import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EpoLoadingIndicator } from 'site-modules/shared/components/appraisal/trade-in-appraiser-steps/epo-processing/epo-loading-indicator/epo-loading-indicator';
import { Spinner } from 'site-modules/shared/components/spinner/spinner';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { Button } from 'site-modules/shared/components/inventory/button/button';

import './llm-loading-screen.scss';

const PROCESS_STEP_COPY = ['Reviewing your preferences', 'Searching thousands of cars', 'Loading results'];
const PROCESS_STEPS = {
  start: -1,
  reviewing: 0,
  searching: 1,
  completed: 2,
};
const PROCESS_TIMES = [1500, 1500, 500];
const LOADING_SCREEN_CREATIVE_ID = 'loading-state-semantic-inventory-search';

function fireWidgetViewTracking(isLoadingStateFired) {
  if (!isLoadingStateFired) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_WIDGET_VIEW,
      event_data: {
        action_name: TrackingConstant.ACTION_WIDGET_VIEW,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        total_page_height: Math.max(document.body.scrollHeight, document.body.offsetHeight),
        creative_id: LOADING_SCREEN_CREATIVE_ID,
      },
    });

    return !isLoadingStateFired;
  }

  return isLoadingStateFired;
}

export function LlmLoadingScreen({
  setLoadingStatus,
  isSubmittingFinished,
  onCancel,
  isLlmDrawerOpen,
  isCancelDisabled,
}) {
  const [loadingStepNumber, setLoadingStepNumber] = useState(PROCESS_STEPS.start);
  const nextStepTimeoutId = useRef(null);
  const isLoadingStateFired = useRef(false);
  const divRef = useRef();

  const clearTimeouts = useCallback(() => {
    if (nextStepTimeoutId.current) {
      clearTimeout(nextStepTimeoutId.current);
    }
  }, []);

  useEffect(() => {
    divRef.current?.focus();
  }, []);

  useEffect(() => {
    if (isLlmDrawerOpen) {
      if (loadingStepNumber < PROCESS_STEPS.searching) {
        nextStepTimeoutId.current = setTimeout(() => {
          setLoadingStepNumber(loadingStepNumber + 1);
        }, PROCESS_TIMES[loadingStepNumber + 1]);
      }
      if (loadingStepNumber === PROCESS_STEPS.searching) {
        isLoadingStateFired.current = fireWidgetViewTracking(isLoadingStateFired.current);
      }
      if (loadingStepNumber === PROCESS_STEPS.completed) {
        fireWidgetViewTracking(isLoadingStateFired.current);
        setTimeout(() => {
          setLoadingStatus();
        }, PROCESS_TIMES[PROCESS_STEPS.completed]);
      }
    }

    return () => {
      clearTimeouts();
    };
  }, [loadingStepNumber, setLoadingStatus, clearTimeouts, isLlmDrawerOpen]);

  const forceTimeouts = useCallback(() => {
    clearTimeouts();
    setLoadingStepNumber(PROCESS_STEPS.completed);
  }, [clearTimeouts]);

  useEffect(() => {
    if (isSubmittingFinished) forceTimeouts();
  }, [isSubmittingFinished, forceTimeouts]);

  return (
    <div
      className="llm-loading-screen pos-a text-center"
      data-tracking-parent={LOADING_SCREEN_CREATIVE_ID}
      role="region"
      tabIndex={-1}
      ref={divRef}
    >
      <div className="heading-2 text-center mb-1_5">
        Revving up your <br />
        <i className="text-blue-50">perfect</i> matches
      </div>
      <div className="mx-auto text-start size-16 d-flex justify-content-center align-items-center">
        <div>
          <EpoLoadingIndicator isSubmittingFinished={loadingStepNumber === PROCESS_STEPS.completed} />
          <ul className="list-unstyled mt-1_5" aria-label="Loading steps">
            {PROCESS_STEP_COPY.map((copy, index) => {
              const isStepDone = index <= loadingStepNumber;
              return (
                <Fragment key={copy}>
                  <li className={classnames('mb-1 d-flex', { 'opacity-5': !isStepDone })}>
                    {isStepDone ? (
                      <i aria-hidden className="icon icon-checkmark-circle size-20 text-green-50 me-1" />
                    ) : (
                      <div className="spinner-container">
                        <Spinner className="me-1" size={20} thickness={2} color="green-50" />
                      </div>
                    )}
                    <div
                      className={classnames({
                        'fw-medium text-gray-darker': isStepDone,
                        'fw-normal text-cool-gray-40': !isStepDone,
                      })}
                    >
                      {copy}
                    </div>
                  </li>
                </Fragment>
              );
            })}
          </ul>
        </div>
      </div>
      <Button
        className="cancel-btn text-cool-gray-30 text-transform-none size-16 fw-medium mt-0_5 py-0_5 px-1"
        onClick={onCancel}
        data-tracking-id="drawer_close"
        data-tracking-value="cancel"
        disabled={isCancelDisabled}
        aria-label="Cancel loading"
      >
        Cancel
      </Button>
    </div>
  );
}

LlmLoadingScreen.propTypes = {
  isSubmittingFinished: PropTypes.bool.isRequired,
  isLlmDrawerOpen: PropTypes.bool.isRequired,
  setLoadingStatus: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isCancelDisabled: PropTypes.bool,
};

LlmLoadingScreen.defaultProps = {
  isCancelDisabled: false,
};
