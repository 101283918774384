import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover } from 'reactstrap'; // eslint-disable-line no-restricted-imports
import { PriceDropAlerts } from 'site-modules/shared/components/profile/price-drop-alerts/price-drop-alerts';
import { PriceDropAlertsList } from 'site-modules/shared/components/profile/price-drop-alerts-list/price-drop-alerts-list';

import './price-drop-alerts-popover.scss';

export function PriceDropAlertsPopover(props) {
  const { showAlerts, isGlobalNavDesktop } = props;
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const popoverRef = useRef();
  const btnRef = useRef();

  function toggle(event) {
    if (event && event.currentTarget === popoverRef.current) {
      // exit by Esc keyboard
      btnRef.current.focus();
    }
    setPopoverOpen(!isPopoverOpen);
  }

  function close() {
    btnRef.current.focus();
    setPopoverOpen(!isPopoverOpen);
  }

  useEffect(() => {
    function handleDocumentFocus(event) {
      const element = event.target;
      const subtreeRoot = popoverRef.current;
      const isInDOMSubtree = subtreeRoot && (element === subtreeRoot || subtreeRoot.contains(element));
      if (isPopoverOpen && !isInDOMSubtree) {
        setPopoverOpen(!isPopoverOpen);
      }
    }
    if (isPopoverOpen) {
      popoverRef.current.setAttribute('aria-hidden', false);
      popoverRef.current.focus();
      document.addEventListener('focus', handleDocumentFocus, true);
    }

    return () => {
      if (isPopoverOpen) {
        document.removeEventListener('focus', handleDocumentFocus, true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverOpen]);

  return (
    <li className="insider-notifications-container">
      <button
        id="insider-notifications-btn"
        className={classNames('btn-link nav-link px-0_5 px-md-1 text-shadow-contrast', {
          'me-0_5': !isGlobalNavDesktop,
          'me-0': isGlobalNavDesktop,
        })}
        disabled={showAlerts === null}
        title="Open My notifications"
        aria-label="Open My notifications"
        ref={btnRef}
      >
        <span className="insider-icon icon-bell2 text-white" aria-hidden />
        {showAlerts && <PriceDropAlerts hideAlertsPerSession={isPopoverOpen} className="ms-0_5" />}
      </button>
      <Popover
        placement="bottom"
        target="insider-notifications-btn"
        innerClassName="px-1 py-1 pos-r"
        isOpen={isPopoverOpen}
        toggle={toggle}
        container=".insider-notifications-container"
        trigger="legacy"
        innerRef={popoverRef}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="price-drop-header"
      >
        <PriceDropAlertsList showAlerts={showAlerts} />
        <div className="close-wrapper pos-a">
          <button
            onClick={close}
            aria-label="Close notifications"
            title="Close notifications"
            className="btn btn-close visually-hidden visually-hidden-focusable"
          >
            <span className="icon-cross3" aria-hidden />
          </button>
        </div>
      </Popover>
    </li>
  );
}

PriceDropAlertsPopover.propTypes = {
  showAlerts: PropTypes.bool,
  isGlobalNavDesktop: PropTypes.bool,
};

PriceDropAlertsPopover.defaultProps = {
  showAlerts: false,
  isGlobalNavDesktop: false,
};
