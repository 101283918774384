import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const routes = [
  {
    path: `/:make(${makes.join('|')})-lease-deals-:locationPartialUrl`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "make_lease_deals_city_state" */ 'client/site-modules/make/pages/make-lease-deals/make-lease-deals-city-state-definition')
    ),
    chunkName: 'make_lease_deals_city_state',
  },
  {
    path: `/:make(${makes.join('|')})-lease-deals`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "make_lease_deals" */ 'client/site-modules/make/pages/make-lease-deals/make-lease-deals-definition')
    ),
    chunkName: 'make_lease_deals',
    enableLooseEtag: true,
  },
];

export { routes };
