import React, { Component, Fragment } from 'react';
import { CmsEntities } from 'client/data/models/cms';
import { AnimatedCollapse } from 'client/site-modules/shared/utils/collapse-utils';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classNames from 'classnames';
import { set } from 'lodash';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './faq.scss';

export class Faq extends Component {
  static propTypes = {
    entry: CmsEntities.Content.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      accordionStatus: this.initAccordion(props),
    };
  }

  initAccordion = props =>
    props.entry.children().reduce((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {});

  toggle = e => {
    const key = e.target.name;
    const { accordionStatus } = this.state;
    this.setState({ accordionStatus: set(accordionStatus, key, !accordionStatus[key]) });
  };

  makeFaqItem = (item, key) => {
    const { accordionStatus } = this.state;
    const { question, answer } = item;
    return (
      <div className="pb-1_25 pb-md-2 medium" key={key}>
        <Row tag="button" onClick={this.toggle} name={key} className="border-0 question w-100 text-primary ps-0_5">
          <Col xs={1} className="question-item d-flex align-items-center px-0">
            <i
              className={classNames('align-self-center', {
                'icon-plus3': !accordionStatus[key],
                'icon-minus3': accordionStatus[key],
              })}
            />
          </Col>
          <Col xs={11} tag="span" className="question-item text-start fw-medium">
            {question}
          </Col>
        </Row>

        <AnimatedCollapse isOpen={accordionStatus[key]}>
          <Row className="pt-1_25 pt-md-1_5">
            <Col xs={1} />
            <Col xs={11}>
              <ContentFragment className="text-gray-darker fw-medium ps-0_75">{answer}</ContentFragment>
            </Col>
          </Row>
        </AnimatedCollapse>
      </div>
    );
  };

  render() {
    const { entry } = this.props;
    if (!entry || !entry.children().length) {
      return null;
    }

    const faqEntries = entry.children();
    return (
      <Fragment>
        <Row className="faq">
          <Col xs={12}>
            <div className="header fw-bold text-gray-darker display-1 pb-2">FAQ</div>
            {faqEntries.map(item => this.makeFaqItem(item.getAllMetadata(), item.id))}
          </Col>
        </Row>
      </Fragment>
    );
  }
}
