import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { MediaEntities } from 'client/data/models/media';
import {
  getFullImageUrl,
  getYoutubePreviewImage,
  isContentSrc,
  isDamSrc,
  YOUTUBE_PREVIEW_SIZE,
} from 'client/utils/image-helpers';
import { ellipsisTruncate } from 'client/utils/string-utils';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

export function PlaylistItem({
  className,
  thumbnailClassName,
  linkClassName,
  video,
  index,
  loadVideo,
  isCurrentVideo,
  playlistHasCurrentVideo,
  isPlaying,
  isMobile,
}) {
  if (!playlistHasCurrentVideo && isCurrentVideo) return null;

  const isCustomThumbnail = isDamSrc(video.thumbnailURL) || isContentSrc(video.thumbnailURL);
  const thumbnailUrl = isCustomThumbnail
    ? getFullImageUrl(video.thumbnailURL)
    : getYoutubePreviewImage(video.videoId, YOUTUBE_PREVIEW_SIZE.MQ);
  const videoUrl = `https://www.youtube.com/watch?v=${video.videoId}`;

  const isSponsored = video.isSponsored && video.isSponsored === 'true';
  const clickoutUrl = video.clickUrl;

  const adName = isMobile ? 'mvideotile' : 'videotile';

  function handleCtaClick() {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_WEBSITE_CLICKOUT,
        subaction_name: TrackingConstant.OEM_SITE_CLICKOUT,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        value: 'Learn More',
      },
    });
  }

  const renderContent = () => (
    <Fragment>
      <div
        className={classnames(
          'thumbnail-wrapper pos-r',
          {
            'pointer-events-none': isSponsored,
          },
          thumbnailClassName
        )}
        style={{ width: '120px', flexShrink: '0' }}
      >
        <AspectRatioContainer aspectRatio="16:9">
          <ClientSideRender>
            <img className="w-100" src={thumbnailUrl} alt={`watch ${video.videoTitle} video`} loading="lazy" />
          </ClientSideRender>
        </AspectRatioContainer>
      </div>
      <div
        className={classnames('title col medium ps-1 text-gray-darker', {
          'fw-bold': isCurrentVideo,
          'pointer-events-none': isSponsored,
        })}
      >
        {!isSponsored && ellipsisTruncate(video.videoTitle, 60)}
        {isSponsored && (
          <div
            style={{
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              maxHeight: '40px',
            }}
          >
            <span>{video.videoTitle}</span>
          </div>
        )}
        {isSponsored && (
          <div className="d-flex medium pointer-events-auto">
            <span className="text-primary-darker">
              Learn More <span className="icon-open_in_new ms-0_25 small" aria-hidden />
            </span>
            <AdLabel styles={{ position: 'static', marginLeft: '8px', marginTop: '2px', height: '16px' }} />
          </div>
        )}
      </div>
    </Fragment>
  );

  return (
    <li
      className={classnames('playlist-item', className, {
        'now-playing': isCurrentVideo && isPlaying,
        'pos-r is-sponsored': isSponsored,
      })}
    >
      <a
        href={videoUrl}
        className={classnames(
          'd-flex',
          {
            'pos-a': isSponsored,
          },
          linkClassName
        )}
        onClick={loadVideo(video, index)}
        title={video.videoTitle}
      >
        {!isSponsored && renderContent()}
      </a>
      {isSponsored && (
        <Fragment>
          <a className={classnames('cta-link d-flex', linkClassName)} href={clickoutUrl} onClick={handleCtaClick}>
            {renderContent()}
          </a>
          <AdUnit adName={adName} position={'1'} oneByOne all renderWhenViewable={false} />
        </Fragment>
      )}
    </li>
  );
}

PlaylistItem.propTypes = {
  loadVideo: PropTypes.func.isRequired,
  video: MediaEntities.Video.isRequired,
  index: PropTypes.number.isRequired,
  className: PropTypes.string,
  thumbnailClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  isCurrentVideo: PropTypes.bool,
  playlistHasCurrentVideo: PropTypes.bool,
  isPlaying: PropTypes.bool,
  isMobile: PropTypes.bool,
};

PlaylistItem.defaultProps = {
  className: null,
  thumbnailClassName: null,
  linkClassName: null,
  isCurrentVideo: false,
  playlistHasCurrentVideo: true,
  isPlaying: false,
  isMobile: false,
};
