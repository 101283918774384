import React from 'react';
import PropTypes from 'prop-types';
import { Video } from 'site-modules/shared/components/video';
import { HtmlVideoPlayer } from 'site-modules/shared/components/html-video-player/html-video-player';
import { parseVideoProps } from './utils';

import './media-content.scss';

export function MediaContent({ media }) {
  const videoProps = parseVideoProps(media);
  const { header, 'media-image': imageSrc } = media;
  return (
    <div className="media-container">
      {!videoProps && imageSrc && <img className="w-100 me-3" src={imageSrc} alt={header} />}
      {videoProps && (
        <div>
          {videoProps.type !== 'HtmlVideoPlayer' && (
            <Video videoId={videoProps.videoId} type={videoProps.type} showRelated={0} className={'w-100 h-100'} />
          )}
          {videoProps.type === 'HtmlVideoPlayer' && (
            <HtmlVideoPlayer src={videoProps.src} poster={imageSrc} type={'video/quicktime'} autoPlay loop />
          )}
        </div>
      )}
    </div>
  );
}

MediaContent.propTypes = {
  media: PropTypes.shape({
    'youtube-videoID': PropTypes.string,
    'static-video': PropTypes.string,
    'media-image': PropTypes.string,
  }).isRequired,
};
