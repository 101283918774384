import React from 'react';

import './salesforce-form.scss';

export function SalesforceForm() {
  return (
    <div className="form-container">
      <h1>Marketo-SF Test</h1>
      <form
        action="https://edmunds--staging.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00DOy0000056cVx"
        method="POST"
      >
        <input type="hidden" name="orgid" value="00DOy0000056cVx" />
        <input type="hidden" name="recordType" value="012G0000001IonD" />
        <input type="hidden" name="retURL" value="http://edmunds.com" />

        <div className="form-group">
          <label htmlFor="00NG000000Els9d">First Name</label>
          <input
            id="00NG000000Els9d"
            name="00NG000000Els9d"
            type="text"
            maxLength={60}
            placeholder="Enter your first name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="00NG000000Els9e">Last Name</label>
          <input
            id="00NG000000Els9e"
            name="00NG000000Els9e"
            type="text"
            maxLength={60}
            placeholder="Enter your last name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="00NG000000Els9c">Email Address</label>
          <input
            id="00NG000000Els9c"
            name="00NG000000Els9c"
            type="email"
            maxLength={80}
            placeholder="Enter your email address"
          />
        </div>

        <div className="form-group">
          <label htmlFor="00NG000000EiRcf">Dealership Name</label>
          <input
            id="00NG000000EiRcf"
            name="00NG000000EiRcf"
            type="text"
            maxLength={255}
            placeholder="Enter dealership name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="00N0f00000G8qXR">Products</label>
          <select id="00N0f00000G8qXR" name="00N0f00000G8qXR" multiple>
            <option value={1}>Product 1</option>
            <option value={2}>Product 2</option>
            <option value={3}>Product 3</option>
          </select>
          <small>Hold Ctrl (Cmd on Mac) to select multiple options.</small>
        </div>

        <div className="form-submit">
          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  );
}
