import { useEffect } from 'react';

export const useClickOutside = (ref, callback, { isEnabled, useBubbling }) => {
  useEffect(() => {
    function handleClick(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    }

    if (isEnabled) {
      document.addEventListener('click', handleClick, !useBubbling);
    }

    return () => {
      document.removeEventListener('click', handleClick, !useBubbling);
    };
  }, [callback, isEnabled, ref, useBubbling]);
};
