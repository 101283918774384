export const CTA_TYPE = {
  USED: {
    BASE: 'usedBase',
  },
  NEW: {
    BASE: 'newBase',
  },
  ONLINE_LEASE: 'onlineLeaseEligible',
  CONTACT_AT_ONCE: 'contactAtOnce', // AutoNation chatclient/site-modules/inventory/components/utils/lead-form-ctas.js
  CALL_DEALER: 'callDealer',
  DETAILS: 'details',
  DIGITAL_RETAIL: 'digitalRetail',
  USED_LOAN_DR: 'usedLoanDigitalRetail',
  NEW_LOAN_DR: 'newLoanDigitalRetail',
  NEW_LEASE_DR: 'newLeaseDigitalRetail',
};

export const DR_CTAS = [CTA_TYPE.USED_LOAN_DR, CTA_TYPE.NEW_LOAN_DR, CTA_TYPE.NEW_LEASE_DR];

/* Ctas which are displayed separately from others */
export const SEPARATELY_DISPLAYED_CTAS = [CTA_TYPE.CONTACT_AT_ONCE];

/* Ctas that should be hidden on mobile */
export const EXCLUDED_FROM_MOBILE = [CTA_TYPE.CALL_DEALER];

/* Ctas that open Lead form drawer */
export const LEAD_FORM_DRAWER_OPENING_CTAS = [CTA_TYPE.NEW.BASE, CTA_TYPE.USED.BASE];

export const CTA_SIZE = {
  FULL: 12,
  FULL_CENTERED: 10,
  CALL_DEALER: 7,
  HALF: 6,
};

export const CTA_TEXT = {
  DETAILS: 'Details',
  REQUEST_QUOTE: 'Request Quote',
  REQUEST_INFORMATION: 'Request Information',
  SPECIAL_OFFER: 'Special Offer',
  CALL_DEALER: 'Call Dealer',
  LEASE_IT_NOW: 'Lease It Now',
  IM_INTERESTED: "I'm Interested",
  GET_DEALER_OFFER: 'Get Dealer Offer',
  COMPARE_DEALER_PRICES: 'Compare dealer prices',
  RATE_DEALER_PRICES: 'Rate dealer prices',
  REQUEST_APPOINTMENT: 'Request Appointment',
  SHOW_EDMUNDS_DEAL: 'Show The Edmunds Deal',
  START_YOUR_DEAL: 'Start Your Deal',
  SEE_REAL_PAYMENTS: 'See Real Payments',
  BUY_ONLINE: 'Buy Online',
  CUSTOMIZE_PAYMENT: 'Customize Payment',
  BUILD_DEAL: 'Build Your Deal',
  KNOW_DEAL: 'Know The Deal',
  CHECK_AVAILABILITY: 'Check Availability',
  CONTACT_DEALER: 'Contact Dealer',
  GET_A_GOOD_DEAL: 'Get a Good Deal',
  RATE_YOUR_DEAL: 'Rate Your Deal',
  PRICING_GUIDE: 'Pricing Guide',
  HOW_TO_RATE_THE_DEAL: 'How to Rate the Deal',
  NEGOTIATE: 'Negotiate',
  COMPARE_DEALER_OFFERS: 'Compare Dealer Offers',
  // TODO: review during cleanup of shop-1239-edmunds-deal
  EDMUNDS_PRICE_CHECKER: 'Edmunds Price Checker',
  GET_DEALERS_BEST_PRICE: 'Get Dealer’s Best Price',
  CHECK_PRICING: 'Check Pricing',
};
