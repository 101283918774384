import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import appleAppStoreLogo from 'client/images/apple-app-store.svg';
import googlePlayStoreLogo from 'client/images/google-play-store.svg';

const APPLE_APP_STORE_DOWNLOAD_LINK = 'https://apps.apple.com/us/app/edmunds-shop-cars-for-sale/id393630966';
const GOOGLE_PLAY_STORE_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.edmunds';

const APP_DOWNLOADS = [
  {
    link: APPLE_APP_STORE_DOWNLOAD_LINK,
    image: {
      path: appleAppStoreLogo,
      alt: 'Download Edmunds app on Apple app store',
      width: 120,
      height: 40,
    },
    attributes: {
      target: '_blank',
      rel: 'nofollow noopener',
    },
    trackingValue: 'App Store',
  },
  {
    link: GOOGLE_PLAY_STORE_DOWNLOAD_LINK,
    image: {
      path: googlePlayStoreLogo,
      alt: 'Download Edmunds app on Google play store',
      width: 135,
      height: 40,
    },
    attributes: {
      target: '_blank',
      rel: 'nofollow noopener',
    },
    trackingValue: 'Google Play',
  },
];

export const AppDownload = ({ className, isAds10216Chal }) => {
  const appDownloadLength = APP_DOWNLOADS.length;

  return (
    <div className={className}>
      <h2
        className={classnames('heading-5', {
          'mb-0_75 fw-medium': isAds10216Chal,
          'mb-1': !isAds10216Chal,
        })}
      >
        Download the Edmunds app
      </h2>
      <ul className="list-unstyled d-flex mb-0">
        {APP_DOWNLOADS.map((appDownload, index) => (
          <li
            key={appDownload.image.path}
            className={classnames({
              'me-2_5': index < appDownloadLength - 1 && !isAds10216Chal,
              'me-0_75': index < appDownloadLength - 1 && isAds10216Chal,
            })}
          >
            <a
              href={appDownload.link}
              data-tracking-id="download_app_click"
              data-tracking-value={appDownload.trackingValue}
              {...appDownload.attributes}
            >
              <img
                src={appDownload.image.path}
                alt={appDownload.image.alt}
                width={appDownload.image.width}
                height={appDownload.image.height}
                loading="lazy"
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
AppDownload.propTypes = {
  className: PropTypes.string,
  isAds10216Chal: PropTypes.bool,
};

AppDownload.defaultProps = {
  className: '',
  isAds10216Chal: false,
};
