import React, { Fragment, useRef, useState } from 'react';
import { trimEnd, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import classnames from 'classnames';
import { scroller } from 'react-scroll';
import { useOnScreen } from 'site-modules/shared/hooks/use-on-screen';

import './truncated-text.scss';

function truncate({ text, maxTextLength }) {
  const isTruncateBecauseOfLength = text.length > maxTextLength;

  let indexToSplit = text.lastIndexOf(' ', maxTextLength);
  while (indexToSplit > 0 && text[indexToSplit - 1].match(/\s/)) {
    indexToSplit -= 1;
  }

  if (!isTruncateBecauseOfLength || indexToSplit === -1) {
    return {
      textFirstPart: text.trim(),
      textSecondPart: '',
    };
  }

  return {
    textFirstPart: trimStart(text.substring(0, indexToSplit)),
    textSecondPart: trimEnd(text.substring(indexToSplit)),
  };
}

export function TruncatedText({
  text,
  className,
  isInlineBtn,
  maxTextLength,
  additionalContent,
  alwaysShowAdditionalContent,
  resetParams,
  btnClassName,
  indicatorOpenClass,
  indicatorCloseClass,
  btnTextLess,
  btnTextMore,
  expandTrackingId,
  expandTrackingValue,
  collapseTrackingId,
  collapseTrackingValue,
  expandAriaLabel,
  collapseAriaLabel,
}) {
  const [isCollapsed, setCollapsed] = useState(true);
  const componentTop = useRef();
  const [shouldNotScrollReset] = useOnScreen(componentTop, { rootMargin: '0px 0px' });

  // if we have additional content to show, we should ALWAYS truncate, unless alwaysShowAdditionalContent is true
  const isTruncateBecauseOfAdditionalContent = !alwaysShowAdditionalContent && !!additionalContent;
  const isTruncateBecauseOfLength = text.length > maxTextLength;
  if (!(isTruncateBecauseOfAdditionalContent || isTruncateBecauseOfLength)) {
    return (
      <div className={`truncated-text ${className}`}>
        {text}
        {additionalContent}
      </div>
    );
  }

  const { textFirstPart, textSecondPart } = truncate({ text, maxTextLength });

  function toggleCollapse() {
    if (resetParams && !isCollapsed && !shouldNotScrollReset) {
      scroller.scrollTo(resetParams.to, {
        duration: resetParams.duration,
        offset: resetParams.offset,
        smooth: 'easeInOutQuad',
        isDynamic: true,
      });
    }

    setCollapsed(!isCollapsed);
  }

  const getButton = isOpenBtn => {
    const indicatorOpen = indicatorOpenClass ? <span className={indicatorOpenClass} aria-hidden /> : '';
    const indicatorClose = indicatorOpenClass ? <span className={indicatorCloseClass} aria-hidden /> : '';

    return (
      <Button
        className={classnames(`truncate-button mb-0_25 p-0`, btnClassName, {
          body: !isOpenBtn,
          'btn-inline': isInlineBtn,
          'd-none': (isOpenBtn && !isCollapsed) || (!isOpenBtn && isCollapsed),
        })}
        color="link"
        onClick={toggleCollapse}
        aria-label={isOpenBtn ? expandAriaLabel : collapseAriaLabel}
        {...(isOpenBtn && expandTrackingId
          ? {
              'data-tracking-id': expandTrackingId,
              'data-tracking-value': expandTrackingValue,
            }
          : {})}
        {...(!isOpenBtn && collapseTrackingId
          ? {
              'data-tracking-id': collapseTrackingId,
              'data-tracking-value': collapseTrackingValue,
            }
          : {})}
      >
        <span>{isOpenBtn ? btnTextMore : btnTextLess}</span>
        {isOpenBtn ? indicatorOpen : indicatorClose}
      </Button>
    );
  };

  const expandBtn = getButton(true);
  const collapseBtn = getButton(false);

  /* For accessibility it is beneficial to have two buttons, one for hiding and one for showing. */
  return (
    <Row className={classnames(`truncated-text`, className)}>
      <Col xs={12}>
        <span ref={componentTop} />
        <span className={classnames({ 'me-0_25': isInlineBtn && isCollapsed })}>
          {textFirstPart}
          {isCollapsed && <Fragment>&nbsp;&hellip;</Fragment>}
        </span>
        <span className={classnames({ 'd-none': isCollapsed, 'me-0_25': isInlineBtn })}>{textSecondPart}</span>

        {!isCollapsed && !alwaysShowAdditionalContent && additionalContent}

        {isInlineBtn && (
          <Fragment>
            {expandBtn}
            {collapseBtn}
          </Fragment>
        )}
      </Col>
      {!isInlineBtn && (
        <Fragment>
          <Col xs={12}>{expandBtn}</Col>
          <Col xs={12}>{collapseBtn}</Col>
        </Fragment>
      )}
      {alwaysShowAdditionalContent && additionalContent && <Col xs={12}>{additionalContent}</Col>}
    </Row>
  );
}

TruncatedText.propTypes = {
  additionalContent: PropTypes.node,
  alwaysShowAdditionalContent: PropTypes.bool,
  isInlineBtn: PropTypes.bool,
  btnClassName: PropTypes.string,
  btnTextLess: PropTypes.string,
  btnTextMore: PropTypes.string,
  className: PropTypes.string,
  indicatorCloseClass: PropTypes.string,
  indicatorOpenClass: PropTypes.string,
  maxTextLength: PropTypes.number,
  text: PropTypes.string.isRequired,
  expandTrackingId: PropTypes.string,
  expandTrackingValue: PropTypes.string,
  collapseTrackingId: PropTypes.string,
  collapseTrackingValue: PropTypes.string,
  expandAriaLabel: PropTypes.string,
  collapseAriaLabel: PropTypes.string,
  resetParams: PropTypes.shape({
    offset: PropTypes.number,
    duration: PropTypes.number,
    to: PropTypes.string,
  }),
};

TruncatedText.defaultProps = {
  additionalContent: null,
  alwaysShowAdditionalContent: false,
  isInlineBtn: false,
  btnClassName: null,
  btnTextLess: 'Read Less',
  btnTextMore: 'Read More',
  className: null,
  indicatorCloseClass: null,
  indicatorOpenClass: null,
  maxTextLength: 85,
  expandTrackingId: null,
  expandTrackingValue: null,
  collapseTrackingId: null,
  collapseTrackingValue: null,
  expandAriaLabel: null,
  collapseAriaLabel: null,
  resetParams: null,
};
