export const TOOLTIPS_TEXT = {
  TOTAL_MSRP: {
    text:
      'The Dealer Offer Price is the offer price of the car made by the dealer to you, the customer. It includes any non-manufacturer related discounts that the dealer may provide in a special offer.',
    placement: 'bottom',
  },
  DUE_AT_SIGNING: {
    text:
      'This is our estimate of how much you will have to pay to drive off in the vehicle that you want to lease. It typically includes your down payment, first month’s payment, security deposit (if any), and estimated taxes and fees not included in your monthly payment.',
    placement: 'bottom',
  },
  TAXES: {
    text: 'Estimated state and local taxes.',
    placement: 'bottom',
  },
  FEES: {
    text: 'Estimated fees include title, license, documentation and registration fees.',
    placement: 'bottom',
  },
  LEASE_FEES: {
    text: 'Estimated government fees include title, license, and registration fees.',
    placement: 'bottom',
  },
  AMOUNT_FINANCED: {
    text:
      'This is our estimate of the amount being financed by the lender, which we calculate based on the Edmunds True Market Value for this vehicle after any current generally available lease incentives, less the amount cash due at signing.',
    placement: 'bottom',
  },
  CREDIT_SCORE: {
    text:
      'Your approximate credit score is used to personalize your payment. A good credit score is typically between 700 and 750, and an excellent credit score is typically above 750.',
    placement: 'bottom',
  },
  ZIP_CODE: {
    text: 'Your ZIP Code will help us provide the best available information for incentives, taxes and fees.',
    placement: 'bottom',
  },
  MONTHLY_PAYMENT: {
    text: 'Includes estimated sales tax on your monthly payment.',
    placement: 'bottom',
  },
  PAYMENTS_DUE_AT_SIGNING: {
    text:
      'Includes down payment, first month’s payment, security deposit (if any) and estimated taxes and fees not included in your monthly payment.',
    placement: 'bottom',
  },
  BASE_PAYMENT: {
    text: 'Excludes capitalized taxes and fees.',
    placement: 'bottom',
  },
  GOOD_DEAL: {
    text:
      'Your Estimated Price for this vehicle is based on Edmunds TMV (True Market Value) and includes all generally available incentives.  Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.',
    placement: 'bottom',
  },
  RECOMMENDED_PAYMENT_PRICE: {
    text:
      'This is what we recommend you pay (not including taxes or fees). It is our Edmunds TMV (True Market Value), which is based on our analysis of millions of vehicle data points and all generally available current incentives and rebates (and any other incentives and rebates you selected or that we selected for you based on your indicated attributes). Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.',
    placement: 'bottom',
  },
  ESTIMATED_PRICE_BEFORE_REBATES: {
    text:
      'This is the sales price you expect to pay for the vehicle, before incentives. We base our estimate of the Vehicle Price on the Edmunds Suggested Price before rebates, which is what we recommend you pay, not including taxes or fees. It is based on our analysis of millions of data points, comprising Edmunds TMV (True Market Value) and all generally available rebates. Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.',
    placement: 'bottom',
  },
  LEASE_PRICE_AFTER_REBATES: {
    text:
      'This is the sales price you expect to pay for the vehicle before taxes, gov’t fees, and lease acquisition fee. We base our estimate of the Vehicle Price on the Edmunds Suggested Price after incentives, which is what we recommend you pay, not including taxes or fees. It is based on our analysis of millions of data points, comprising Edmunds TMV (True Market Value) and all generally available rebates. Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.',
    placement: 'bottom',
  },
  PURCHASE_PRICE_AFTER_REBATES: {
    text:
      'This is the sales price you expect to pay for the vehicle before taxes, and gov’t fees. We base our estimate of the Vehicle Price on the Edmunds Suggested Price after incentives, which is what we recommend you pay, not including taxes or fees. It is based on our analysis of millions of data points, comprising Edmunds TMV (True Market Value) and all generally available rebates. Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.',
    placement: 'bottom',
  },
  CUSTOMER_CASH: {
    text: 'This is the total cash lease incentives applied to your deal.',
    placement: 'bottom',
  },
  MONEY_FACTOR: {
    text:
      'The Money Factor is an alternative method of presenting the amount of interest charged on a lease with monthly payments. The money factor can be translated into the more common annual percentage rate (APR) by multiplying the money factor by 2,400. Money factor is also known as a "lease factor" or a "lease fee".',
    placement: 'bottom',
  },
  RESIDUAL_VALUE: {
    text:
      'The residual value is the amount you can buy the car for at the end of the lease, if you decide you want to purchase the car.',
    placement: 'bottom',
  },
  MSRP: {
    text: 'Manufacturer Suggested Retail Price',
    placement: 'bottom',
  },
  TOTAL_SAVINGS: {
    text:
      'Total saving is the sum of the Edmunds market adjustment applied to the selling price of the car off of MSRP, and the applicable incentives listed below.',
    placement: 'bottom',
  },
  MARKET_ADJUSTMENT: {
    text:
      'The difference between MSRP and the Edmunds Suggested Price for this vehicle (not taking into account incentives, taxes, or fees).',
    placement: 'bottom',
  },
  APR: {
    text:
      'The Annual Percentage Rate is the annual rate charged for borrowing. For a lease, this rate is commonly expressed as a Money Factor.',
    placement: 'bottom',
  },
  RESIDUAL_PERCENTAGE: {
    text:
      "The Residual Percentage is an expression of how much of a car's original MSRP, not selling price, will remain at the end of a lease of specific term (months) and specific mileage.",
    placement: 'bottom',
  },
  ESTIMATED_SAVINGS: {
    text: 'Edmunds’ Estimated Savings is the difference between MSRP and Edmunds TMV (True Market Value).',
    placement: 'bottom',
  },
  TRADE_IN: {
    text:
      'This is the amount you can expect to receive when you trade in your used vehicle and purchase or lease a new vehicle. This amount is equal to the appraised value of your trade-in, less the money you still owe on the car. The trade-in value applied to your deal is usually credited as a down payment on the new vehicle.',
    placement: 'bottom',
  },
  DOWN_PAYMENT: {
    text:
      'For vehicle purchase, the amount of your down payment. For a vehicle lease, an initial payment that reduces the capitalized cost (i.e. the amount you finance) or that is applied to the other amounts payable at signing.',
    placement: 'bottom',
  },
  ACQUISITION_FEE: {
    text: 'A fee that leasing companies charge to arrange the lease.',
    placement: 'bottom',
  },
  EDMUNDS_ESTIMATED_SAVINGS: {
    text:
      'Your savings from MSRP if you pay the Edmunds Suggested Price for this vehicle (not taking into account incentives, taxes or fees).',
    placement: 'bottom',
  },
  ANNUAL_MILES: {
    text: 'In most leases, you are allowed to drive only 10,000 to 15,000 miles per year.',
    placement: 'bottom',
  },
  CONDITIONAL_INCENTIVE_PRICE: {
    text: 'Incentive price depends on selected Term',
    placement: 'bottom',
  },
  VEHICLE_PRICE: {
    text:
      'Edmunds Suggested Price is what we recommend you pay (not including taxes or fees). It is our Edmunds TMV (True Market Value), which is based on our analysis of millions of vehicle data points and all generally available current incentives and rebates (and any other incentives and rebates you selected or that we selected for you based on your indicated attributes). Edmunds TMV is a trusted industry benchmark for the average price paid for this type of vehicle in your area.',
    placement: 'bottom',
  },
};

export const SAVINGS_TOOLTIP = isMarketAdjustment =>
  isMarketAdjustment ? TOOLTIPS_TEXT.MARKET_ADJUSTMENT : TOOLTIPS_TEXT.EDMUNDS_ESTIMATED_SAVINGS;

export const EXCELLENT = 'Excellent';
export const AVERAGE = 'Average';
export const GOOD = 'Good';
export const POOR = 'Poor';
export const FAIR = 'Fair';
export const LOW = 'Low';
export const MEDIUM = 'Medium';
export const HIGH = 'High';

export const CALCULATOR_TYPE_LOAN = 'loan';
export const CALCULATOR_TYPE_LEASE = 'lease';
export const CALCULATOR_TYPE_LOAN_LEASE = 'loan,lease';
export const CALCULATOR_TYPE_LEASE_LOAN = 'lease,loan';
export const CALCULATOR_VDP = 'vdp';
export const CALCULATOR_TED = 'ted';

export const CREDIT_SCORE_EXCELLENT = EXCELLENT;
export const CREDIT_SCORE_FAIR = FAIR;
export const CREDIT_SCORE_GOOD = GOOD;
export const CREDIT_SCORE_POOR = POOR;
export const APR_NAME = 'apr';
export const MONTHS_NAME = 'numberOfMonths';
export const DOWNPAYMENT_NAME = 'downPayment';
export const TRADE_IN_NAME = 'tradeIn';
export const DISPLAY_PRICE = 'displayPrice';
export const CREDIT_SCORE_NAME = 'creditScore';
export const TERM_NAME = 'term';
export const MONTHLY_LEASE_PAYMENT_NAME = 'monthlyLeasePayment';
export const ANNUAL_MILES_NAME = 'annualMileage';
export const MONEY_FACTOR_NAME = 'moneyFactor';
export const MSRP_NAME = 'msrp';
export const TAXES_TOGGLE_NAME = 'taxesAndFees';
export const APPRAISAL_VALUE_NAME = 'appraisalValue';
export const PAYOFF_AMOUNT_NAME = 'amountOwed';
export const APPLIED_TRADE_IN_NAME = 'appliedTradeIn';
export const CUSTOM_VEHICLE_PRICE_NAME = 'customVehiclePrice';
export const TRADE_IN_OWED_AMOUNT = 'tradeInOwedAmount';
// style ID for cases when style ID is missed, so default values, for instance, for APR will be returned
export const DEFAULT_STYLE_ID = -1;

export const CREDIT_SCORE_VALUES = {
  [CREDIT_SCORE_EXCELLENT]: { value: 800, leaseValue: 800, min: 750, max: 850 },
  [CREDIT_SCORE_GOOD]: { value: 725, leaseValue: 700, min: 700, max: 749 },
  [CREDIT_SCORE_FAIR]: { value: 675, leaseValue: 640, min: 640, max: 699 },
  [CREDIT_SCORE_POOR]: { value: 625, min: 0, max: 639 },
};

export const CREDIT_SCORES = [
  {
    name: CREDIT_SCORE_POOR,
    rate: '639 or less',
  },
  {
    name: CREDIT_SCORE_FAIR,
    rate: '640 - 699',
  },
  {
    name: CREDIT_SCORE_GOOD,
    rate: '700 - 749',
  },
  {
    name: CREDIT_SCORE_EXCELLENT,
    rate: '750 - 850',
  },
];

export const RESIDUAL_VALUES = {
  [LOW]: 54,
  [MEDIUM]: 62,
  [HIGH]: 68,
};

export const CREDIT_TIER_VALUES = {
  [POOR]: 0.00392,
  [FAIR]: 0.00246,
  [GOOD]: 0.00225,
  [EXCELLENT]: 0.00183,
};

export const REVERSED_CREDIT_TIER_VALUES = {
  [CREDIT_TIER_VALUES[POOR]]: POOR,
  [CREDIT_TIER_VALUES[FAIR]]: FAIR,
  [CREDIT_TIER_VALUES[GOOD]]: GOOD,
  [CREDIT_TIER_VALUES[EXCELLENT]]: EXCELLENT,
};

export const CREDIT_TIER_LABELS = Object.keys(CREDIT_TIER_VALUES);

export const LEASE_TRADE_IN = 'leaseTradeIn';
export const LEASE_DOWN_PAYMENT = 'leaseDownPayment';

export const LEASE_INPUT_NAMES = {
  [LEASE_TRADE_IN]: 'tradeIn',
  [LEASE_DOWN_PAYMENT]: 'downPayment',
};

export const LEASE_TITLES = {
  CREDIT_SCORE_TITLE: 'Approx. Credit Score',
  DOWN_PAYMENT_TITLE: 'Down Payment',
  TRADE_IN_TITLE: 'Trade-In Value',
  TERM_TITLE: 'Term (Months)',
  LEASE_TERM_TITLE: 'Lease Term (months)',
  ANNUAL_MILES_TITLE: 'Annual Miles',
};

export const LOAN_TITLES = {
  CREDIT_SCORE_TITLE: 'Approx. Credit Score',
  DOWN_PAYMENT_TITLE: 'Down Payment',
  TRADE_IN_TITLE: 'Trade-In Value',
  TERM_TITLE: 'Term (Months)',
  ANNUAL_RATE_TITLE: 'Estimated APR',
  SPECIAL_APR_TITLE: 'Special APR',
  ALTERNATIVE_APR: 'Alternative APR',
};

export const VEHICLE_PRICE = 'Vehicle Price';

export const MONTHLY_PAYMENT_UNAVAILABLE = 'unavailable';

export const LEASE_TERMS = [12, 24, 36, 39, 48];
export const LEASE_TERMS_CALCULATOR = [24, 36, 39, 48];
export const LEASE_MILES = [10000, 12000, 15000];

export const LOAN_TERMS = [36, 48, 60, 72, 84];

export const SHARED_PAYMENTS = [TRADE_IN_NAME, DOWNPAYMENT_NAME, CUSTOM_VEHICLE_PRICE_NAME];

export const DEFAULT_APR = 4.4;

export const PAYMENT_CALCULATOR_NAME = 'payment-calculator';

export const LIMIT_TRADE_IN = 'TRADE_IN';
export const LIMIT_DOWN_PAYMENT = 'DOWN_PAYMENT';

export const PAYMENT_TYPE_STORAGE_KEY = 'preference.payment';

export const DEFAULT_VEHICLE_SALES_PRICE = 35000;
export const DEFAULT_ANNUAL_MILEAGE = LEASE_MILES[1];
export const DEFAULT_MONEY_FACTOR = CREDIT_TIER_VALUES[EXCELLENT];
export const DEFAULT_DOWN_PAYMENT = 4000;
export const DEFAULT_LEASE_DOWN_PAYMENT = 1000;
export const DEFAULT_LEASE_DOWN_PAYMENT_LOW = 2500;
export const DEFAULT_RESIDUAL_VALUE = RESIDUAL_VALUES[MEDIUM];
export const DEFAULT_TERM = 60;
export const DEFAULT_TRADE_IN = 0;
export const DEFAULT_LOAN_DOWN_PAYMENT = 0;
export const DEFAULT_LEASE_TERM = 36;
export const DEFAULT_LEASE_ANNUAL_MILEAGE = 12000;

export const TAXES_TITLES_MAP = {
  title: {
    name: 'Taxes',
    slug: 'combinedSalesTax',
    isBold: true,
    notAvailableText: 'Tax estimates are not currently available',
  },
  items: [
    {
      name: 'City Tax',
      slug: 'citySalesTax',
    },
    {
      name: 'County Tax',
      slug: 'countySalesTax',
    },
    {
      name: 'District Tax',
      slug: 'districtSalesTax',
    },
    {
      name: 'State Tax',
      slug: 'stateSalesTax',
    },
    {
      name: 'Gas Guzzler Tax',
      slug: 'gasGuzzlerTax',
      tooltip: {
        text:
          'The Gas Guzzler Tax amount is already included in the Vehicle Price and shown here for your visibility. It is not included in the total tax amount or payment with taxes and fees since it’s already accounted for',
        placement: 'bottom',
      },
    },
  ],
};

export const ACQUISITION_FEE_TITLE_ITEM = {
  name: 'Acquisition Fee',
  slug: 'acquisitionFee',
  tooltip: {
    text: 'A fee that leasing companies charge to arrange the lease',
    placement: 'bottom',
  },
};

export const DOC_FEE_TITLE_ITEM = {
  name: 'Dealer Documentation/Processing Charge or Fee',
  slug: 'docFee',
  tooltip: {
    text: 'A fee charged by the dealership to process the vehicle’s paperwork',
    placement: 'bottom',
  },
};

export const FEES_TITLES_MAP = {
  title: {
    name: 'Fees',
    slug: 'combinedFees',
    isBold: true,
    notAvailableText: 'Fees are not currently available',
  },
  items: [
    {
      name: 'Title Fee',
      slug: 'titleFee',
      tooltip: {
        text: 'The fee assessed when the title is transferred to the purchaser',
        placement: 'bottom',
      },
    },
    {
      name: 'Registration Fee',
      slug: 'registrationFee',
      tooltip: {
        text: 'The fee the state charges to register a vehicle, assign a title and cover the cost of license plates',
        placement: 'bottom',
      },
    },
    {
      name: 'Miscellaneous',
      slug: 'dmvFee',
      tooltip: {
        text: 'All other estimated government fees',
        placement: 'bottom',
      },
    },
    DOC_FEE_TITLE_ITEM,
  ],
};

export const FEES_TITLES_MAP_EXTENDED = {
  ...FEES_TITLES_MAP,
  items: [...FEES_TITLES_MAP.items, ACQUISITION_FEE_TITLE_ITEM],
};

export const TAXES_AND_FEES_TOOLTIP_INFO = {
  taxesAndFees: {
    text: 'Estimated taxes and fees based on zip code entered',
    placement: 'bottom',
  },
  paymentWithTaxesAndFees: {
    text: 'Estimated Monthly Payment with Taxes and Fees',
    placement: 'bottom',
  },
  fees: {
    text: 'Estimated fees based on zip code entered',
    placement: 'bottom',
  },
  paymentWithFees: {
    text: 'Estimated Monthly Payment with Fees',
    placement: 'bottom',
  },
  paymentWithDealerFees: {
    text: 'Estimated Monthly Payment with Dealer Fees',
    placement: 'bottom',
  },
};

export const NON_CAPITALIZED_TAXES_AND_FEES_SUM = 'nonCapitalizedTaxesAndFees';
export const VDP_CALCULATOR_CREATIVE_ID = 'edm-entry-payment-calculator';
export const NEGATIVE_TRADE_IN_MSG =
  "Since you owe more on your trade-in than it's worth, your trade-in value is negative and increases your monthly payment.";
export const INVALID_CONDITIONAL_INCENTIVE_MSG =
  "Note: This offer can't be combined with any other offer and was not applied to your deal.";
export const CONFLICTED_CONDITIONAL_INCENTIVE_MSG =
  "Note: This offer can't be combined with the other offers you selected. We have applied the offer with the highest value to your deal resulting in the lowest payment possible.";
export const DEFAULT_INFO_MSG = '';
export const TOTAL_TRADE_IN_MSG =
  'This is equal to appraised value of your vehicle, less the money you still owe on it.';
export const APPLIED_TRADE_IN_MSG = 'This is the value of your trade-in that we estimate will be applied to your deal.';
export const NEGATIVE_VALIDATION_MESSAGE = name =>
  `Please enter a ${name.toLowerCase()} amount that's equal to or greater than zero`;
export const PRICE_ANALYSIS_LINK = 'How are my savings calculated?';
export const PRICE_ANALYSIS_LINK_DEFAULT = 'Price details';
export const PRICE_ANALYSIS_LINK_WITH_INCENTIVES = 'Price and available incentive details';
export const SAVINGS_SUMMARY_DRAWER_HEADER_DEFAULT = 'Your savings summary:';
export const VEHICLE_PRICE_VALIDATION_MESSAGE = `Please enter a vehicle price between $1 and $10,000,000.`;

export const ESSENTIAL_PAYMENTS_FIELDS = ['annualMileage', 'leaseTerm'];
export const RESIDUAL_VALUE = 'residualValue';
export const DEFAULT_RESIDUAL = 0.62;
export const DEFAULT_ACQUISITION_FEE = 995;

export const DEFAULT_CREDIT_SCORE = 750;

export const DEFAULT_LOAN_CRITERIA = {
  salesTaxRate: 9.125,
  salesPrice: 35000,
  term: 60,
  downPayment: 4000,
  cashIncentives: 0,
  interestRate: 4.4,
  owedTradeIn: 0,
  titleFees: 0,
  tradeIn: 0,
};
