import { PUB_STATES } from 'client/constants/pub-states';
import { TrackingConstant } from 'client/tracking/constant';

export const PAGE_NAMES = {
  APPRAISER_OFFER_RENEWAL: 'used_cars_tmv_appraiser_renewal',
};

export const LOCATION_AND_STYLE = 'location-style';
export const COLORS_AND_OPTIONS = 'colors-and-options';
export const CONDITION_AND_MILEAGE = 'condition-mileage';
export const REPORT = 'report';

export const LICENSE_PLATE_AND_VIN = 'license-plate-and-vin';
export const PARTNER_OFFER_CONDITIONS = 'partner-offer-conditions';
export const INSTANT_OFFER = 'instant-offer';

export const TRADE_IN_APPRAISER_NAME = 'trade-in-appraiser';
export const TRADE_IN_APPRAISER_HEADER = 'trade-in-header';

export const DRAWER_CREATIVE_ID = 'vdp-appraisal-drawer';
export const APPRAISAL_PAGE_CREATIVE_ID = 'edm-entry-appraisal-page';
export const APPRAISER_REPORT_CREATIVE_ID = 'edm-entry-appraisal-page-report';
export const APPRAISER_OFFER_REPORT_CREATIVE_ID = 'edm-entry-offer-page-report';
export const APPRAISER_OFFER_NO_REPORT_CREATIVE_ID = 'edm-entry-offer-page-no-report';
export const APPRAISER_NO_REPORT_CREATIVE_ID = 'edm-entry-appraisal-page-no-report';
export const PARTNER_OFFER_TRACKING_CREATIVE_ID = 'edm-entry-partner-offer';
export const PARTNER_OFFER_LOCATION_TRACKING_CREATIVE_ID = 'edm-entry-partner-offer-location';
export const LICENSE_PLATE_AND_VIN_CREATIVE_ID = 'edm-entry-epo-vin';
export const LICENSE_PLATE_AND_VIN_SECOND_CHANCE_CREATIVE_ID = 'edm-entry-epo-vin-second-chance';
export const PARTNER_OFFER_CONDITION_TRACKING_CREATIVE_ID = 'edm-entry-partner-offer-condition';
export const INSTANT_OFFER_STEP_TRACKING_CREATIVE_ID = 'edm-entry-offer-step-view';
export const PARTNER_OFFER_INSTANT_OFFER_TRACKING_CREATIVE_ID = 'edm-entry-epo-offer';
export const PARTNER_OFFER_APPRAISAL_CHECKOUT_CREATIVE_ID = 'edm-entry-epo-offer-appraisal-checkout';
export const PARTNER_OFFER_NO_INSTANT_OFFER_TRACKING_CREATIVE_ID = 'edm-entry-epo-no-offer';
export const PARTNER_OFFER_NO_OFFER_TMV_TRACKING_CREATIVE_ID = 'edm-entry-epo-no-offer-tmv';
export const PARTNER_OFFER_ESTIMATED_OFFER_CREATIVE_ID = 'edm-entry-epo-estimated-offer';
export const PARTNER_OFFER_NO_OFFER_NO_TMV_CREATIVE_ID = 'edm-entry-epo-no-offer-no-tmv';
export const APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID = 'appraisal_vehicle_entry';
export const APPRAISAL_VEHICLE_ENTRY_VIN_LOOKUP_CREATIVE_ID = 'appraisal_vehicle_entry_vin_lookup';
export const PARTNER_OFFER_ADDRESS_LOOKUP = 'edm-entry-epo-address-lookup';
export const PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID = 'epo-appointment-drawer';
export const APPRAISAL_OFFER_RENEWAL_CREATIVE_ID = 'edm-entry-epo-offer-renewal';
export const APPRAISAL_OFFER_RENEWAL_NO_INSTANT_OFFER_CREATIVE_ID = 'edm-entry-epo-no-offer-renewal';
export const APPRAISAL_OFFER_RENEWAL_ENTRY_CREATIVE_ID = 'edm-entry-appraisal-renewal';
export const APPRAISAL_OFFER_RENEWAL_ERROR_CREATIVE_ID = 'edm-entry-appraisal-renewal-error';
export const APPRAISAL_TRACK_CAR_CREATIVE_ID = 'edm-entry-track-car-value';
export const APPRAISAL_HISTORY_VEHICLE_SELECTION_CREATIVE_ID = 'appraisal_history_vehicle_selection';
export const APPRAISAL_HISTORY_GRAPH_CREATIVE_ID = 'edm-entry-appraisal-history-graph';

export const EPO_UUIDV5_NAMESPACE = '22876566-4b0a-457c-9f7b-054edba6e617';

export const LOCATION_AND_STYLE_STEP = {
  id: LOCATION_AND_STYLE,
  nextId: COLORS_AND_OPTIONS,
  prevIds: [],
  title: () => 'Location and Style',
  isActive: true,
  isDone: false,
};

export const COLORS_AND_OPTIONS_STEP = {
  id: COLORS_AND_OPTIONS,
  nextId: CONDITION_AND_MILEAGE,
  prevIds: [LOCATION_AND_STYLE],
  title: () => 'Colors and Options',
  isActive: false,
  isDone: false,
};

export const REPORT_STEP = {
  id: REPORT,
  prevIds: [LOCATION_AND_STYLE, COLORS_AND_OPTIONS, CONDITION_AND_MILEAGE],
  title: () => 'Appraisal Report',
  isActive: false,
  isDone: false,
  isFinal: true,
};

export const LICENSE_PLATE_AND_VIN_STEP = {
  id: LICENSE_PLATE_AND_VIN,
  nextId: INSTANT_OFFER,
  prevIds: [LOCATION_AND_STYLE, COLORS_AND_OPTIONS, CONDITION_AND_MILEAGE, PARTNER_OFFER_CONDITIONS],
  title: () => 'VIN/License Plate',
  isActive: false,
  isDone: false,
  trackingParentId: LICENSE_PLATE_AND_VIN_CREATIVE_ID,
};

const shouldSkipLpAndVin = ({ queryVin, isVinFromDrawer, hasBeenSkipped }) =>
  (queryVin || isVinFromDrawer) && !hasBeenSkipped;

export const PARTNER_OFFER_CONDITIONS_STEP = {
  id: PARTNER_OFFER_CONDITIONS,
  nextId: LICENSE_PLATE_AND_VIN,
  prevIds: [LOCATION_AND_STYLE, COLORS_AND_OPTIONS, CONDITION_AND_MILEAGE],
  title: () => 'Condition Questions',
  isActive: false,
  isDone: false,
  trackingParentId: PARTNER_OFFER_CONDITION_TRACKING_CREATIVE_ID,
  skipToStepObj: {
    shouldSkipHappen: shouldSkipLpAndVin,
    hasBeenSkipped: false,
    skipToStepId: INSTANT_OFFER,
    markCompleteId: LICENSE_PLATE_AND_VIN,
  },
};

export const INSTANT_OFFER_STEP = {
  id: INSTANT_OFFER,
  prevIds: [
    LOCATION_AND_STYLE,
    COLORS_AND_OPTIONS,
    CONDITION_AND_MILEAGE,
    PARTNER_OFFER_CONDITIONS,
    LICENSE_PLATE_AND_VIN,
  ],
  title: () => 'Edmunds Appraisal Report',
  isActive: false,
  isDone: false,
  isFinal: true,
  trackingParentId: PARTNER_OFFER_TRACKING_CREATIVE_ID,
};

export const CONDITION_AND_MILEAGE_STEP = {
  id: CONDITION_AND_MILEAGE,
  nextId: PARTNER_OFFER_CONDITIONS,
  prevIds: [LOCATION_AND_STYLE, COLORS_AND_OPTIONS],
  title: () => 'Condition and Mileage',
  isActive: false,
  isDone: false,
};

/**
 * Intentional duplicate isFinal = true in INSTANT_OFFER step hides "Continue" button + shows final links for EVA-1512.
 * isFinal controls vertical bar for the step number and removes it if isFinal equals to true.

 */
export const APPRAISER_STEPS = [
  LOCATION_AND_STYLE_STEP,
  COLORS_AND_OPTIONS_STEP,
  {
    ...CONDITION_AND_MILEAGE_STEP,
    nextId: REPORT,
  },
  REPORT_STEP,
];

export const PARTNER_OFFER_APPRAISER_STEPS = [
  LOCATION_AND_STYLE_STEP,
  COLORS_AND_OPTIONS_STEP,
  CONDITION_AND_MILEAGE_STEP,
  PARTNER_OFFER_CONDITIONS_STEP,
  LICENSE_PLATE_AND_VIN_STEP,
  INSTANT_OFFER_STEP,
];

export const PARTNER_FAQ_VISIBLE_BY_APPRAISER_STEP_ID = [
  COLORS_AND_OPTIONS,
  CONDITION_AND_MILEAGE,
  REPORT,
  PARTNER_OFFER_CONDITIONS,
  LICENSE_PLATE_AND_VIN,
  INSTANT_OFFER,
];

export const APPRAISER_STEP_SELECT_TRACKING = 'select_step';

export const APPRAISER_STEP_BUTTON_TRACKING = {
  [LOCATION_AND_STYLE]: 'submit_location_style',
  [COLORS_AND_OPTIONS]: 'submit_colors_options',
  [CONDITION_AND_MILEAGE]: 'submit_condition_mileage',
  [REPORT]: 'add_estimated_payment',
  [PARTNER_OFFER_CONDITIONS]: 'partner_submit_questions',
};

export const REPORT_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: 'vdp-appraisal-drawer-report',
  },
};

const NO_REPORT_CREATIVE_ID = {
  [APPRAISER_REPORT_CREATIVE_ID]: APPRAISER_NO_REPORT_CREATIVE_ID,
  [APPRAISER_OFFER_REPORT_CREATIVE_ID]: APPRAISER_OFFER_NO_REPORT_CREATIVE_ID,
};

export const NO_REPORT_VIEW_TRACKING = creativeId => ({
  ...REPORT_VIEW_TRACKING,
  event_data: {
    ...REPORT_VIEW_TRACKING.event_data,
    creative_id: NO_REPORT_CREATIVE_ID[creativeId],
  },
});

export const REPORT_VIEW_TRACKING_EXTENDED_REPORT = creativeId => ({
  ...REPORT_VIEW_TRACKING,
  event_data: {
    ...REPORT_VIEW_TRACKING.event_data,
    creative_id: creativeId,
  },
});

export const UNIVERSAL_VIN_ENDPOINT_TRACKING = (styles, creativeId) => {
  let value = 'none';
  if (styles.length === 1) {
    value = 'one';
  } else if (styles.length > 1) {
    value = 'many';
  }

  return {
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      subaction_name: TrackingConstant.UNIVERSAL_VIN_ENDPOINT,
      creative_id: creativeId,
      value,
    },
  };
};

export const SAVE_ESTIMATED_PAYMENTS_TRACKING = {
  event_type: 'action_completed',
  event_data: {
    action_cause: 'user_input',
    action_name: 'tool_select',
    action_category: 'user',
    subaction_name: 'input_trade_in_value',
    creative_id: 'edm-entry-payment-calculator',
  },
};

export const LICENSE_PLATE_AND_VIN_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: LICENSE_PLATE_AND_VIN_CREATIVE_ID,
  },
};

export const LICENSE_PLATE_AND_VIN_SECOND_CHANCE_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: LICENSE_PLATE_AND_VIN_SECOND_CHANCE_CREATIVE_ID,
  },
};

export const PARTNER_OFFER_CONDITION_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: PARTNER_OFFER_CONDITION_TRACKING_CREATIVE_ID,
  },
};

export const INSTANT_OFFER_VIEW_TRACKING = creativeId => ({
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: creativeId,
  },
});

/**
 * The specific pixel below has a GTM trigger for "Facebook Signal" (Temporarily Disabled via EVAL-3115), and "AdWords".
 * Triggers off by the following values: event_type, creative_id
 *
 * See EVAL-3115(Facebook Signal Disabled), EVAL-2204(Facebook Signal), EVAL-2260 for more information.
 */
export const PARTNER_OFFER_INSTANT_OFFER_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: PARTNER_OFFER_INSTANT_OFFER_TRACKING_CREATIVE_ID,
  },
};

export const GTM_FB_AUDIENCE_OFFER_PROS_TRACKING = {
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: TrackingConstant.SUBACTION_GTM_FB_AUDIENCE_PROS,
    creative_id: PARTNER_OFFER_INSTANT_OFFER_TRACKING_CREATIVE_ID,
  },
};

export const PARTNER_OFFER_ESTIMATED_OFFER_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: PARTNER_OFFER_ESTIMATED_OFFER_CREATIVE_ID,
  },
};

export const PARTNER_OFFER_NO_OFFER_NO_TMV_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: PARTNER_OFFER_NO_OFFER_NO_TMV_CREATIVE_ID,
  },
};

export const PARTNER_OFFER_NO_OFFER_TMV_VIEW_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: PARTNER_OFFER_NO_OFFER_TMV_TRACKING_CREATIVE_ID,
  },
};

export const PARTNER_OFFER_APPRAISAL_CHECKOUT_TRACKING = {
  event_type: 'widget_view',
  event_data: {
    action_cause: 'button_click',
    creative_id: PARTNER_OFFER_APPRAISAL_CHECKOUT_CREATIVE_ID,
  },
};

export const PARTNER_OFFER_NO_INSTANT_OFFER_VIN_TRACKING = vin => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: TrackingConstant.SUBACTION_EPO_NO_OFFER_VIN,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
    value: vin,
  },
});

export const PARTNER_OFFER_ESTIMATED_OFFER_VIN_TRACKING = vin => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: TrackingConstant.SUBACTION_EPO_ESTIMATED_OFFER_VIN,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    creative_id: PARTNER_OFFER_ESTIMATED_OFFER_CREATIVE_ID,
    value: vin,
  },
});

export const PARTNER_OFFER_PROPENSITY_SCORE_TRACKING = (subaction, value) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: subaction,
    value,
  },
});

export const EPO_OFFER_TRACKING = ({
  creativeId,
  valuation,
  errorValue,
  eventType = TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
}) => {
  let value;
  if (errorValue) {
    value = errorValue;
  } else {
    value = valuation || TrackingConstant.ZERO_VALUATION;
  }

  return {
    event_type: eventType,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      creative_id: creativeId,
      subaction_name: TrackingConstant.SUBACTION_EPO_OFFER,
      value,
    },
  };
};

export const OFFER_CODE_TRACKING = (creativeId, code) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    subaction_name: TrackingConstant.SUBACTION_OFFER_CODE,
    creative_id: creativeId,
    value: code || TrackingConstant.NO_OFFER_CODE,
  },
});

export const EPO_OFFER_VIN_TRACKING = (creativeId, vin) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    subaction_name: TrackingConstant.SUBACTION_EPO_OFFER_VIN,
    creative_id: creativeId,
    value: vin,
  },
});

export const DECLINE_REASON_VALUE_TRACKING = (creativeId, declineReason) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
  event_data: {
    action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
    subaction_name: TrackingConstant.SUBACTION_DECLINE_REASON_VALUE,
    creative_id: creativeId,
    value: declineReason,
  },
});

export const OFFER_ESTIMATE_SOURCE_TRACKING = hasMetadataEstimates => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    subaction_name: 'offer_estimate_source',
    creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
    value: hasMetadataEstimates ? 'patch' : 'estimator',
  },
});

export const TMV_CONDITION_ERROR_TRACKING = EPO_OFFER_TRACKING({
  creativeId: PARTNER_OFFER_TRACKING_CREATIVE_ID,
  errorValue: 'TMV_REPORT_ERROR',
  eventType: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
});

export const VIEW_APPRAISAL_DROPDOWN_SELECT_TRACKING = (value, creativeId) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_START,
  event_data: {
    action_name: TrackingConstant.ACTION_VIEW_CONTENT,
    subaction_name: TrackingConstant.VIEW_APPRAISAL,
    action_category: TrackingConstant.USER_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
    creative_id: creativeId,
    value,
  },
});

export const MULTI_OFFER_RECEIVE_MODS_ID_TRACKING = (value, creativeId) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: TrackingConstant.MODS_ID,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    creative_id: creativeId || PARTNER_OFFER_TRACKING_CREATIVE_ID,
    value,
  },
});

export const MULTI_OFFER_MODS_RECORD_CREATION_ERROR_TRACKING = (value, creativeId) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: TrackingConstant.MODS_RECORD_CREATION_ERROR,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    creative_id: creativeId || PARTNER_OFFER_TRACKING_CREATIVE_ID,
    value,
  },
});

export const getMultiOfferPrequalificationTrackingEvent = (partner, value, creativeId) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: `${TrackingConstant.EMO_PREQUALIFY_RESULT}_${partner}`,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.SYSTEM_ACTION_CAUSE,
    creative_id: creativeId,
    value,
  },
});

export const getMultiOfferPrequalificationIneligibleTrackingEvent = (partner, value, creativeId) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: `${TrackingConstant.EMO_PREQUALIFY_INELIGIBLE_REASON}_${partner}`,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.SYSTEM_ACTION_CAUSE,
    creative_id: creativeId,
    value,
  },
});

export const getMultiOfferSolicitVinTrackingEvent = vin => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    subaction_name: TrackingConstant.SOLICIT_MORE_OFFERS_VIN,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.SYSTEM_ACTION_CAUSE,
    creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
    value: vin,
  },
});

export const CONTINUE_BUTTON_CLASSNAME = 'continue-button';

export const CONDITIONS = {
  OUTSTANDING: 'Outstanding',
  CLEAN: 'Clean',
  AVERAGE: 'Average',
  ROUGH: 'Rough',
  DAMAGED: 'Damaged',
};

export const NOT_VALID_CONDITION_LIST = [CONDITIONS.DAMAGED];

const CONDITION_BASE_TEXTS = {
  [CONDITIONS.OUTSTANDING]:
    'Exceptional mechanical, exterior and interior condition with no visible wear; no reconditioning required.',
  [CONDITIONS.CLEAN]:
    'Minimal wear and tear with no major mechanical or cosmetic problems; may require limited reconditioning.',
  [CONDITIONS.AVERAGE]:
    'Normal wear and tear. May have a few mechanical and/or cosmetic problems and may require some reconditioning.',
  [CONDITIONS.ROUGH]: 'Several mechanical and/or cosmetic problems requiring repairs.',
  [CONDITIONS.DAMAGED]: 'Major mechanical and/or body damage that may render it in non-safe running condition.',
};

export const CONDITION_LIST = [
  {
    title: CONDITIONS.OUTSTANDING,
    text: `Only 5% of used vehicles qualify. ${CONDITION_BASE_TEXTS[CONDITIONS.OUTSTANDING]}`,
  },
  {
    title: CONDITIONS.CLEAN,
    text: `38% of cars. ${CONDITION_BASE_TEXTS[CONDITIONS.CLEAN]}`,
  },
  {
    title: CONDITIONS.AVERAGE,
    text: `45% of cars.  ${CONDITION_BASE_TEXTS[CONDITIONS.AVERAGE]}`,
  },
  {
    title: CONDITIONS.ROUGH,
    text: `9% of cars. ${CONDITION_BASE_TEXTS[CONDITIONS.ROUGH]}`,
  },
  {
    title: CONDITIONS.DAMAGED,
    text: `3% of cars. ${CONDITION_BASE_TEXTS[CONDITIONS.DAMAGED]}`,
  },
];

export const CONDITIONS_FOR_TABLE_DISPLAY = [
  {
    title: CONDITIONS.ROUGH,
    text: CONDITION_BASE_TEXTS[CONDITIONS.ROUGH],
  },
  {
    title: CONDITIONS.AVERAGE,
    text: CONDITION_BASE_TEXTS[CONDITIONS.AVERAGE],
  },
  {
    title: CONDITIONS.CLEAN,
    text: CONDITION_BASE_TEXTS[CONDITIONS.CLEAN],
  },
  {
    title: CONDITIONS.OUTSTANDING,
    text: `${CONDITION_BASE_TEXTS[CONDITIONS.OUTSTANDING]} Approximately 5% of used vehicles qualify.`,
  },
];

export const CONDITIONS_ORDER_FOR_TABLE_DISPLAY = [
  CONDITIONS.ROUGH,
  CONDITIONS.AVERAGE,
  CONDITIONS.CLEAN,
  CONDITIONS.OUTSTANDING,
];

export const PRICE_INFO = {
  NATIONAL_BASE: {
    title: 'National Base Price',
    info:
      "Prices for a vehicle in 'clean' condition and excluding optional equipment and adjustments for vehicle color, region in which the vehicle is located and vehicle mileage outside of the normal range.",
  },
  OPTIONAL_EQUIPMENT: {
    title: 'Optional Equipment',
    info: 'Prices for the optional equipment.',
  },
  COLOR_ADJUSTMENT: {
    title: 'Color Adjustment',
    info: 'Figures reflect the adjustment required to account for color as a driver of vehicle value.',
  },
  REGIONAL_ADJUSTMENT: {
    title: 'Regional Adjustment',
    info: 'Figures reflect the adjustment required to account for regional variations in pricing.',
  },
  MILEAGE_ADJUSTMENT: {
    title: 'Mileage Adjustment',
    info: 'Figures reflect the adjustment required to account for actual vehicle use in terms of normal wear.',
  },
  CONDITION_ADJUSTMENT: {
    title: 'Condition Adjustment',
    info:
      'Figures reflect the adjustment required to account for differences in the way that the vehicle had been maintained.',
  },
};

export const TRADE_IN_VALUE = 'tradeIn';
export const PRIVATE_PARTY_VALUE = 'privateParty';
export const DEALER_RETAIL_VALUE = 'dealerRetail';

export const TRADE_IN_LABEL = 'Trade-In';
export const PRIVATE_PARTY_LABEL = 'Private Party';
export const DEALER_RETAIL_LABEL = 'Dealer Retail';

export const TRANSACTION_INFO = {
  [TRADE_IN_VALUE]: {
    title: TRADE_IN_LABEL,
    info:
      "These True Market Value \u00AE prices are Edmunds' estimates of the average prices dealers are currently paying for this vehicle in your area.",
  },
  [PRIVATE_PARTY_VALUE]: {
    title: PRIVATE_PARTY_LABEL,
    info:
      "These True Market Value \u00AE prices are Edmunds' estimates of the average prices consumers are paying each other in private party transactions in your area.  This is always a higher amount than the trade-in value, but it takes more work because you'll be dealing with offers from buyers.",
  },
  [DEALER_RETAIL_VALUE]: {
    title: DEALER_RETAIL_LABEL,
    info:
      "These True Market Value \u00AE prices are Edmunds' estimates of the average prices consumers are currently paying dealers for this vehicle in your area.",
  },
};

export const USED_TRADE_IN_VALUE = 'usedTradeIn';
export const USED_PRIVATE_PARTY_VALUE = 'usedPrivateParty';
export const USED_DEALER_RETAIL_VALUE = 'usedTmvRetail';

export const PRICE_NAMES_USED = [USED_TRADE_IN_VALUE, USED_PRIVATE_PARTY_VALUE, USED_DEALER_RETAIL_VALUE];

export const TRANSACTION_TYPE_SELECT_LABELS = {
  [TRADE_IN_VALUE]: `${TRADE_IN_LABEL} Value`,
  [PRIVATE_PARTY_VALUE]: `${PRIVATE_PARTY_LABEL} Value`,
  [DEALER_RETAIL_VALUE]: `${DEALER_RETAIL_LABEL} Value`,
};

export const TRANSACTION_TYPE_BUTTONS_OPTIONS = [
  {
    label: TRADE_IN_LABEL,
    value: TRADE_IN_VALUE,
  },
  {
    label: PRIVATE_PARTY_LABEL,
    value: PRIVATE_PARTY_VALUE,
  },
  {
    label: DEALER_RETAIL_LABEL,
    value: DEALER_RETAIL_VALUE,
  },
];

export const TRANSACTION_TYPE_OPTIONS = [
  {
    label: TRANSACTION_TYPE_SELECT_LABELS[TRADE_IN_VALUE],
    value: TRADE_IN_VALUE,
  },
  {
    label: TRANSACTION_TYPE_SELECT_LABELS[PRIVATE_PARTY_VALUE],
    value: PRIVATE_PARTY_VALUE,
  },
  {
    label: TRANSACTION_TYPE_SELECT_LABELS[DEALER_RETAIL_VALUE],
    value: DEALER_RETAIL_VALUE,
  },
];

export const EMAIL_REPORT_STATUSES = {
  SENT: 'sent',
  IN_PROGRESS: 'in_progress',
};

/**
 * ERROR_MESSAGES
 */
export const VIN_HAS_INCORRECT_FORMAT_LENGTH =
  'The VIN you entered does not contain 17 digits. Please make sure you entered your VIN correctly.';
export const VIN_HAS_INCORRECT_FORMAT =
  'This does not appear to be a valid VIN. Please confirm all 17 digits of the VIN are correct.';
export const VIN_IS_NOT_LOCATED = 'We could not locate this VIN. Please confirm you entered the VIN correctly.';

export const REQUIRED_VIN_LENGTH = 17;

export const MORE_INFO = {
  title: 'More Info',
  value:
    'The Total Trade-in Value, also known as trade-in equity, is equal to the appraised value of your car less the money that you still owe on the car. There are limits to the dollar amount of your total trade-in value that can be applied to your deal based on the value of the car you are looking to purchase or lease.',
  PAYOFF_AMOUNT:
    'The amount you owe on your trade-in equals the sum of remaining monthly payments you have yet to make.',
  TOTAL_TRADE_IN:
    'The Total Trade-in Value is equal to the appraised value minus the money that you still owe on the car.',
  APPLIED_TRADE_IN:
    'There are limits to the amount of your trade-in value that can be applied to your deal. This is based on the value of the car you are looking to purchase or lease.',
};

export const TOTAL_TRADE_IN = {
  title: 'Total Trade-In Value:',
};
export const APPLIED_TRADE_IN = {
  title: 'Applied Trade-In Value:',
};

export const INELIGIBILITY_REASONS = {
  YMM_INELIGIBLE: 'ymm-ineligible',
  YMM_AND_ZIP_INELIGIBLE: 'ymm-and-zip-ineligible',
  ACTIVE_APPRAISAL: 'active-appraisal',
  VEHICLE_HISTORY: 'vehicle-history',
};

export const TRACKING_VALUES = {
  mmy_zip_eligible: 'mmy_zip_eligible',
  mmy_zip_ineligible: INELIGIBILITY_REASONS.YMM_AND_ZIP_INELIGIBLE,
  vin_eligible: 'vin_eligible',
  api_failure: 'api-failure',
  no_offer: 'NO_OFFER',
};

export const APPRAISAL_REPORT_TYPE_TRACKING = {
  [REPORT]: {
    [TRADE_IN_VALUE]: REPORT_VIEW_TRACKING_EXTENDED_REPORT(`${APPRAISER_REPORT_CREATIVE_ID}-trade`),
    [PRIVATE_PARTY_VALUE]: REPORT_VIEW_TRACKING_EXTENDED_REPORT(`${APPRAISER_REPORT_CREATIVE_ID}-private`),
    [DEALER_RETAIL_VALUE]: REPORT_VIEW_TRACKING_EXTENDED_REPORT(`${APPRAISER_REPORT_CREATIVE_ID}-dealer`),
  },
  [INSTANT_OFFER]: {
    [TRADE_IN_VALUE]: INSTANT_OFFER_VIEW_TRACKING(`${APPRAISER_OFFER_REPORT_CREATIVE_ID}-trade`),
    [PRIVATE_PARTY_VALUE]: INSTANT_OFFER_VIEW_TRACKING(`${APPRAISER_OFFER_REPORT_CREATIVE_ID}-private`),
    [DEALER_RETAIL_VALUE]: INSTANT_OFFER_VIEW_TRACKING(`${APPRAISER_OFFER_REPORT_CREATIVE_ID}-dealer`),
  },
};

export const ALLOWED_PUB_STATES = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED];

export const CARMAX_PHONE = '8887200696';
export const CARMAX_STORE_URL = 'https://www.carmax.com/stores';

export const DEFAULT_MILEAGE = {
  THRESHOLD: 5000,
  MIN: 100,
};
