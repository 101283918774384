import React, { useCallback, useDeferredValue, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { useLlmResults } from 'site-modules/shared/hooks/global-search/use-llm-results';
import { GlobalSearch } from 'site-modules/shared/components/inventory/global-search/global-search';
import { ApiMetrics, withMetrics } from 'client/utils/metrics-hoc';
import { InventoryEntities } from 'client/data/models/inventory';
import { flow } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { buildSearchIdPath, SemanticSearchModel } from 'client/data/models/semantic-search';
import { transformSuggestedFilters } from 'site-modules/shared/utils/inventory/semantic-search';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { Collapse } from 'site-modules/shared/components/collapse/collapse';
import { LISTBOX_ID, PRIMING_BOX_COPY } from 'site-modules/shared/constants/global-search/global-search';

import './global-navigation-query.scss';

const SEARCH_EDMUNDS = 'Search Edmunds';
const CREATIVE_ID = 'edm-entry-global-nav-semantic-search';

export function GlobalNavigationQueryUI({
  isMobile,
  setModelValue,
  suggestedFilters,
  searchId,
  apiMetrics,
  withLlmSearchDisabled,
  shop3436Recipe,
}) {
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);
  const deferredQuery = useDeferredValue(query);
  const trimmedQuery = deferredQuery?.trim();
  const inputRef = useRef();
  const buttonRef = useRef();

  const { triggerSubmit, triggerChange, isLoading, isError, loadingComponent } = useLlmResults({
    suggestedFilters,
    searchId,
    setModelValue,
    query: trimmedQuery,
  });

  const onSearchChange = useCallback(
    async value => {
      setQuery(value);

      await triggerChange();
    },
    [triggerChange]
  );

  const closeGlobalSearch = useCallback(() => {
    setOpen(false);
    onSearchChange('');
  }, [onSearchChange]);

  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      inputRef.current?.classList?.toggle('d-none', false);
      inputRef.current?.focus();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  useEffect(() => {
    EventToolbox.on(PAGE_EVENTS.PAGE_LOAD, closeGlobalSearch);

    return () => {
      EventToolbox.off(PAGE_EVENTS.PAGE_LOAD, closeGlobalSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function toggleScrolling(disableScroll) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          document.documentElement.classList.toggle('overflow-hidden', !!disableScroll);
        });
      });
    }

    if (!isMobile && isOpen) {
      toggleScrolling(true);
    }

    return () => {
      toggleScrolling();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && isMobile) {
      inputRef.current.classList.toggle('d-none', true);
    }
  }, [isMobile, isOpen]);

  const globalSearch = (
    <GlobalSearch
      creativeId={CREATIVE_ID}
      query={query}
      onSearchSubmit={triggerSubmit}
      onSearchChange={onSearchChange}
      loadingComponent={loadingComponent}
      apiMetrics={apiMetrics}
      isMobile={isMobile}
      isLoading={isLoading}
      isError={isError}
      withLlmSearchDisabled={withLlmSearchDisabled}
      controlled
      isOpen={!isMobile || isOpen}
      onChangeOpenState={setOpen}
      isGlobalNav
      isSearchVisible={isOpen}
    />
  );

  return (
    <div
      className={classnames('global-navigation-query pos-r', {
        'w-100 d-flex justify-content-end': isMobile,
      })}
      style={isMobile ? { justifyContent: 'flex-end' } : null}
    >
      {shop3436Recipe === 'chal1' && (
        <Button
          className="search-edmunds-button pos-r w-100 px-0_75 py-0_5 bg-white text-transform-none fw-normal text-cool-gray-50 size-16 border-0 d-flex align-items-center align-self-center"
          style={{
            fontSize: '1rem',
            lineHeight: '1.375rem',
            height: '48px',
            maxWidth: '200px',
            borderRadius: '28px',
            padding: '0.5rem 0.75rem',
            alignSelf: 'center',
          }}
          onClick={handleClick}
          innerRef={buttonRef}
          aria-expanded={isOpen}
          aria-haspopup="dialog"
          aria-controls={LISTBOX_ID}
        >
          <span className="d-inline-block">
            <img src={getStaticImageUrl('/icons/magic-search.svg')} alt="" decoding="async" loading="lazy" />
          </span>
          <span
            className="pos-a left-0 right-0 ms-3 me-0_75 text-start text-truncate"
            style={{
              position: 'absolute',
              left: '0',
              right: '0',
              marginLeft: '3rem',
              marginRight: '0.75rem',
              overflow: 'hidden',
            }}
          >
            {SEARCH_EDMUNDS}
          </span>
        </Button>
      )}
      {shop3436Recipe === 'chal2' && (
        <Button
          color="link"
          size="sm"
          className="px-1"
          style={{ height: isMobile ? '56px' : '38px', width: '54px', padding: '0.46875rem 1rem' }}
          onClick={handleClick}
          innerRef={buttonRef}
          aria-expanded={isOpen}
          aria-haspopup="dialog"
          aria-controls={LISTBOX_ID}
          aria-label={SEARCH_EDMUNDS}
        >
          <i className="icon-magic-search size-20 text-white" aria-hidden />
        </Button>
      )}

      {/* ios hack to open keyboard in drawer */}
      {isMobile && <input aria-hidden type="text" ref={inputRef} className="visually-hidden" />}

      {!isMobile && (
        <Collapse
          isOpen={isOpen}
          transition="height 300ms linear"
          id={LISTBOX_ID}
          role="dialog"
          aria-label={PRIMING_BOX_COPY}
          className="global-nav-search-collapse"
          style={{ position: 'fixed' }}
        >
          {globalSearch}
        </Collapse>
      )}
      {isMobile && globalSearch}
    </div>
  );
}

GlobalNavigationQueryUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  apiMetrics: ApiMetrics.isRequired,
  searchId: PropTypes.string,
  suggestedFilters: PropTypes.shape({
    filters: PropTypes.arrayOf(InventoryEntities.FacetValue),
    inventoryCount: PropTypes.number,
    modifiedRadius: PropTypes.number,
    identifiedVin: PropTypes.shape({
      vin: PropTypes.string,
      vinInfo: InventoryEntities.InventoryVin,
      styleInfo: PropTypes.arrayOf(
        PropTypes.shape({
          makeNiceId: PropTypes.string,
          modelNiceId: PropTypes.string,
          year: PropTypes.number,
          styleId: PropTypes.number,
        })
      ),
    }),
  }),
  shop3436Recipe: PropTypes.string,
  isMobile: PropTypes.bool,
  withLlmSearchDisabled: PropTypes.bool,
};

GlobalNavigationQueryUI.defaultProps = {
  searchId: '',
  suggestedFilters: {},
  shop3436Recipe: 'chal1',
  isMobile: false,
  withLlmSearchDisabled: false,
};

export const GlobalNavigationQuery = flow(
  withMetrics,
  component =>
    connectToModel(component, {
      suggestedFilters: bindToPath(
        ({ searchId }) => (searchId ? `suggestedFilters["${searchId}"]` : null),
        SemanticSearchModel,
        transformSuggestedFilters,
        false
      ),
    }),
  component =>
    connectToModel(component, {
      searchId: bindToPath(({ searchRoute }) => buildSearchIdPath(searchRoute), SemanticSearchModel),
    })
)(GlobalNavigationQueryUI);
