import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

import './checkbox.scss';

const CheckboxUI = (
  {
    id,
    labelText,
    containerClassName,
    labelClassName,
    customLabelClassName,
    textLabelClassName,
    children,
    className,
    ...props
  },
  ref
) => (
  <div className={classnames('checkbox custom', containerClassName)}>
    <Input
      className={classnames('custom-input m-0', className)}
      id={`checkbox-${id}`}
      type="checkbox"
      innerRef={ref}
      {...props}
    />
    <Label className={classnames('label-container m-0', labelClassName)} for={`checkbox-${id}`}>
      <span className={classnames('d-block custom-label', customLabelClassName)} />
      {labelText && <span className={classnames('d-block text-label', textLabelClassName)}>{labelText}</span>}
    </Label>
    {children}
  </div>
);

export const Checkbox = forwardRef(CheckboxUI);

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelText: PropTypes.node,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  customLabelClassName: PropTypes.string,
  textLabelClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  labelText: null,
  containerClassName: null,
  labelClassName: null,
  customLabelClassName: null,
  children: null,
  textLabelClassName: 'ps-1_25',
  className: '',
};
