export const CTA_TEXTS = {
  CONTINUE: 'Continue with this offer',
  VIEW_CARMAX_OFFER: 'View CarMax Offer',
  PRINT_OFFER: 'Print Offer',
  UNLOCK_OFFER: 'Unlock Offer',
  UNLOCK_OFFER_TO_SEE: "Unlock offer to see who it's from",
  DISCLAIMER: 'Disclaimer',
  VIEW_OFFER_DETAILS: 'View Offer Details',
  SAVE_OFFER: 'Save Offer',
  ACTIVATE_OFFER: 'Activate this offer',
};
