import React, { Fragment } from 'react';
import { get, isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import PropTypes from 'prop-types';
import { Link } from 'site-modules/shared/components/link/link';

export function Article({ item }) {
  const article = item.getAllMetadata();
  if (isEmpty(article)) return null;

  const articleTitle = get(article, 'article-title', '');
  const articleDate = get(article, 'article-date', '');
  const articleText = get(article, 'article-text', '');
  const articlePdfIcon = get(article, 'article-pdf-icon', false);
  const articleLink = get(article, 'article-link', '');
  const articlePdfLinkText = get(article, 'article-pdf-icon-text', '');
  const articleImage = get(article, 'article-image', '');

  return (
    <Fragment>
      <div className="pb-1 d-flex flex-nowrap">
        {articleImage && (
          <div>
            <img src={articleImage} alt={articleTitle} />
          </div>
        )}
        <div className="ps-1 ps-md-1_5">
          {articleTitle && (
            <Link to={articleLink} target="_blank" className="heading-5">
              {articleTitle}
            </Link>
          )}
          {articlePdfIcon === 'true' && (
            <Link to={articleLink} title={articlePdfLinkText} target="_blank">
              <i className="icon-download4 ms-0_25 text-primary-darker heading-6" aria-hidden />
            </Link>
          )}
          {articleDate && <p className="text-gray-darker mb-0_5 medium">{articleDate}</p>}
          {articleText && <p className="d-none d-md-block medium text-gray-darker">{articleText}</p>}
        </div>
      </div>
      {articleText && <p className="pb-2 d-md-none medium text-gray-darker">{articleText}</p>}
    </Fragment>
  );
}

Article.propTypes = {
  item: PropTypes.shape({}),
};

Article.defaultProps = {
  item: {},
};

export function ArticleList({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }

  return entry.children().map((item, index) => <Article item={item} key={`${item.id}-${index.toString()}`} />);
}

ArticleList.propTypes = {
  entry: CmsEntities.Content,
};

ArticleList.defaultProps = {
  entry: null,
};
