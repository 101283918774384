import React from 'react';
import Col from 'reactstrap/lib/Col';
import { get, sortBy } from 'lodash';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { CmsEntities } from 'client/data/models/cms';
import PropTypes from 'prop-types';
import { getHeader, getItems } from 'client/site-modules/industry-center/utils/utils';

import './feature-list.scss';

const FEATURE_LIST_HEADER = 'feature-list-header';

export function FeatureListItem({ item }) {
  const image = get(item, 'item-image', '');
  const text = get(item, 'item-text', '');
  const url = get(item, 'item-url', '');
  const contactName = get(item, 'item-contact-name', '');
  const contactTitle = get(item, 'item-contact-title', '');
  const contactEmail = get(item, 'item-contact-email', '');
  const contactPhone = get(item, 'item-contact-phone', '');

  // contactTitle is not used for general Media Inquries contact card
  const isMediaContact = !!(contactName && contactEmail && contactPhone);
  const itemText = <ContentFragment classes="ps-1 ps-md-1_5 d-inline">{text}</ContentFragment>;
  const itemImage = <img className="rounded-circle" src={image} alt="feature item" />;
  const itemStyle = `d-flex align-items-center container px-0`;

  return (
    <li className="pb-2 pe-1_5">
      {url ? (
        <a href={url} className={`${itemStyle}`} rel="nofollow">
          {itemImage}
          {itemText}
        </a>
      ) : (
        <span className={`${itemStyle} text-gray-darker`}>
          {itemImage}
          {isMediaContact ? (
            <span className="ps-1 ps-md-1_5">
              <h4>{contactName}</h4>
              <p className="mb-0">{contactTitle}</p>
              <a className="media-email" href={`mailto:${contactEmail}`} rel="nofollow">
                {contactEmail}
              </a>
              <p>{contactPhone}</p>
            </span>
          ) : (
            itemText
          )}
        </span>
      )}
    </li>
  );
}

FeatureListItem.propTypes = {
  item: PropTypes.shape({}),
};
FeatureListItem.defaultProps = {
  item: {},
};

export function FeatureList({ entry }) {
  if (!entry || !entry.children().length) {
    return null;
  }

  const { headerText, subHeaderText } = getHeader(entry, FEATURE_LIST_HEADER);
  const featureListEntries = sortBy(getItems(entry, FEATURE_LIST_HEADER), 'id');

  return (
    <Col className="feature-list">
      {headerText && <div className="display-2 text-start text-md-center text-gray-darker">{headerText}</div>}
      {subHeaderText && (
        <ContentFragment
          componentToUse={'div'}
          classes="display-4 text-start text-md-center text-gray-darker pt-1_5 pt-md-0_75"
        >
          {subHeaderText}
        </ContentFragment>
      )}
      <ul className="list-unstyled small mb-0 flex-column flex-md-row align-items-start w-100 list-group-item border-0 px-0 pt-2">
        {featureListEntries.map(item => (
          <FeatureListItem item={item.getAllMetadata()} key={item.id} />
        ))}
      </ul>
    </Col>
  );
}

FeatureList.propTypes = {
  entry: CmsEntities.Content,
};

FeatureList.defaultProps = {
  entry: null,
};
