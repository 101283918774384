import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { SRP_BASE_PATHNAME } from 'site-modules/shared/constants/inventory/routes-constants';
import { makes } from 'client/data/constants/active-makes';

// For now, we have /[make]-dealership/ and /[make]-dealership-city-state/ SRP available only for the following makes
const MAKE_DEALERSHIP_MAKES = [
  'ford',
  'honda',
  'toyota',
  'kia',
  'nissan',
  'dodge',
  'jeep',
  'hyundai',
  'subaru',
  'mazda',
];
const MAKE_DEALERSHIP_CITIES = [
  'houston',
  'denver',
  'san-antonio',
  'austin',
  'san-diego',
  'dallas',
  'las-vegas',
  'los-angeles',
  'phoenix',
  'chicago',
];

/**
 * List of available SRP routes
 */
export const routes = [
  // 0,
  {
    path: [
      SRP_BASE_PATHNAME,
      '/used-:partialFlatUrl',
      '/new-:partialFlatUrl-for-sale',
      '/new-:partialFlatUrl-for-sale-:locationPartialUrl',
      '/lease-deals',
      '/:partialFlatUrl-lease-deals',
      '/:partialFlatUrl-lease-deals-:locationPartialUrl',
      '/cars-for-sale-by-owner',
      `/(${MAKE_DEALERSHIP_MAKES.join('|')})-dealership`,
      `/(${MAKE_DEALERSHIP_MAKES.join('|')})-dealership-(${MAKE_DEALERSHIP_CITIES.join('|')})-:state`,
    ],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "srp" */ '../pages/usurp/usurp-definition')),
    chunkName: 'srp',
    enableLooseEtag: true,
  }, // 1,
  {
    path: [`/:make(${makes.join('|')})/:model/:year/for-sale`],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "srp" */ '../pages/usurp/usurp-definition')),
    chunkName: 'srp',
    shouldCallParseApi: true,
    enableLooseEtag: true,
  }, // 2,
  {
    path: [`/vin-preview-srp/:vin`],
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "vin-preview-srp" */ '../pages/usurp/vin-preview-definition'),
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'vin-preview-srp',
    enableLooseEtag: true,
  },
];
