import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { gql } from '@apollo/client'; // eslint-disable-line
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { APPRAISE_BY_VIN_TAB_EVENT_DATA } from 'site-modules/shared/components/appraisal/appraisal-tabs/constants';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'site-modules/shared/constants/appraisal/appraisal';

export const getVinDecoderStylesData = async (vin, context, apiOptions) => {
  let response;
  try {
    const API = !context ? EdmundsGraphQLFederation : await withMetrics(EdmundsGraphQLFederation, context);
    response = await API.query(
      gql`
        query($vin: String!) {
          vehicleDecodeByVIN(vin: $vin) {
            ... on VINDecodedVehicle {
              vinBuild {
                matchType
                styleName
                exteriorColor {
                  id
                  genericName
                  oemName
                }
                interiorColor {
                  id
                  genericName
                  oemName
                }
                modelYear {
                  id
                  makeSlug
                  makeName
                  modelSlug
                  modelName
                  year
                }
                styles {
                  id
                }
                options {
                  genericFeatures {
                    id
                  }
                }
              }
            }
            ... on StyleDecodedVehicle {
              squishVinInfo {
                id
                styles {
                  id
                  slug
                }
                modelYears {
                  id
                  makeSlug
                  modelSlug
                  year
                }
              }
            }
          }
        }
      `,
      {
        vin,
      },
      apiOptions
    );
  } catch (e) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
        creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
        subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
        value: `${vin}_graphql_vin_decode_error`,
      },
    });
    return { hasError: true, styles: [] };
  }

  const { squishVinInfo, vinBuild = [] } = response.vehicleDecodeByVIN;

  if (vinBuild.length) {
    const { matchType, exteriorColor, interiorColor, modelYear, styles, options } = vinBuild?.[0];
    return {
      matchType,
      exteriorColor,
      interiorColor,
      modelYear,
      styles,
      options,
    };
  } else if (!isEmpty(squishVinInfo)) {
    const { styles, modelYears } = squishVinInfo;
    return {
      styles,
      modelYear: modelYears?.[0],
    };
  }

  return {};
};

const getVinStylesApi = vin => `/vehicle/v3/styles/vins/${vin}`;

export const getVinStylesDataWithMetadata = async ({ vin, isEmo2507Chal, apiOptions }, context) => {
  if (isEmo2507Chal) {
    const { styles, modelYear, hasError } = await getVinDecoderStylesData(vin, context, apiOptions);

    let results = [];

    if (hasError) {
      return {
        hasError,
        results,
      };
    }

    if (styles && modelYear) {
      const { makeSlug, modelSlug, modelName, makeName, year } = modelYear;
      results = styles.map(({ id }) => ({
        styleId: +id,
        makeNiceId: makeSlug,
        modelNiceId: modelSlug,
        year,
        makeName,
        modelName,
      }));
    }

    return { results };
  }

  let response;
  try {
    const API = !context ? EdmundsAPI : await withMetrics(EdmundsAPI, context);
    const results = await API.fetchJson(getVinStylesApi(vin), apiOptions);
    response = {
      results,
    };
  } catch (e) {
    response = {
      hasError: true,
      results: [],
    };
  }
  return response;
};

export const getVinStylesData = async ({ vin, isEmo2507Chal, apiOptions }, context) => {
  const response = await getVinStylesDataWithMetadata({ vin, isEmo2507Chal, apiOptions }, context);
  const { results } = response;

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
      subaction_name: TrackingConstant.SUBACTION_STYLE_DECODE,
      value: results.length,
    },
  });

  return results;
};

const getSquishVinApi = squishVin =>
  `/vehicle/v3/styles?squishVins.squishVin=${squishVin.toUpperCase()}&fields=id,year,makeNiceId,makeName,modelNiceId,modelName`;

const getUniversalVinApi = vin =>
  `/inventory/v5/universalvin/${vin}?fields=vin,vehicleInfo.styleInfo,vehicleInfo.partsInfo,vehicleInfo.vehicleColors`;

export const getSquishVinData = async (squishVin, context) => {
  let response;
  try {
    const API = !context ? EdmundsAPI : await withMetrics(EdmundsAPI, context);
    response = await API.fetchJson(getSquishVinApi(squishVin));
  } catch (e) {
    response = {};
  }
  return get(response, 'results', []);
};

export const getUniversalVinData = async vin => {
  let response;
  try {
    response = await EdmundsAPI.fetchJson(getUniversalVinApi(vin));
  } catch (e) {
    response = [];
  }
  return response;
};

/**
 * NOTE: Vehicle API resolvers NOT specific to VINs can be found in the￼vehicle.js data model.
 * Platform team requested the separation to reduce bloat in vehicle.js.
 */
export const VehicleVinModel = createModelSegment('vehicleVin', [
  /**
   * https://www.edmunds.com/api/vehicle/v3/styles?squishVins.squishVin=1GKKNMLSKZ&fields=id,year,makeNiceId,makeName,modelNiceId,modelName
   */
  {
    path: 'squishVin["{squishVin}"].stylesBasicInfo',
    async resolve({ squishVin }, context) {
      return getSquishVinData(squishVin, context);
    },
  },
  {
    path: 'vin["{vin}"].vinDecoderBasicInfo',
    async resolve({ vin }, context) {
      return getVinDecoderStylesData(vin, context);
    },
  },
  {
    path: 'vin["{vin}"].stylesBasicInfo',
    async resolve({ vin }, context) {
      return getVinStylesData({ vin }, context);
    },
  },
  {
    path: 'vin["{vin}"].vinDecoderStylesBasicInfo',
    async resolve({ vin }, context) {
      return getVinStylesData({ vin, isEmo2507Chal: true }, context);
    },
  },
]);

export function buildStylesBasicPathFromSquishVin({ squishVin }) {
  return squishVin ? `squishVin["${squishVin}"].stylesBasicInfo` : '';
}

export function buildStyleBasicPathFromVinDecoder({ vin }) {
  return vin ? `vin["${vin}"].vinDecoderBasicInfo` : '';
}

export function buildStylesBasicPathFromVin({ vin, isEmo2507Chal }) {
  if (isEmo2507Chal) {
    return vin ? `vin["${vin}"].vinDecoderStylesBasicInfo` : '';
  }

  return vin ? `vin["${vin}"].stylesBasicInfo` : '';
}

const SquishStyle = PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.number,
  makeName: PropTypes.string,
  makeNiceId: PropTypes.string,
  modelName: PropTypes.string,
  modelNiceId: PropTypes.string,
});

const VinDecodeVinDataStyle = PropTypes.shape({
  matchType: PropTypes.string,
  styleName: PropTypes.string,
  exteriorColor: PropTypes.shape({
    id: PropTypes.string,
    genericName: PropTypes.string,
    oemName: PropTypes.string,
  }),
  interiorColor: PropTypes.shape({
    id: PropTypes.string,
    genericName: PropTypes.string,
    oemName: PropTypes.string,
  }),
  modelYear: PropTypes.shape({
    id: PropTypes.string,
    makeSlug: PropTypes.string,
    modelSlug: PropTypes.string,
    year: PropTypes.number,
  }),
  styles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
});

const VinDecodeSquishVinDataStyle = PropTypes.shape({
  id: PropTypes.string,
  styles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  modelYears: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      makeSlug: PropTypes.string,
      modelSlug: PropTypes.string,
      year: PropTypes.number,
    })
  ),
});

const VinStylesBasicInfo = PropTypes.arrayOf(
  PropTypes.shape({
    makeName: PropTypes.string,
    makeNiceId: PropTypes.string,
    modelName: PropTypes.string,
    modelNiceId: PropTypes.string,
    year: PropTypes.number,
    styleId: PropTypes.number,
    styleName: PropTypes.string,
  })
);

export const VehicleVinEntities = {
  SquishStyle,
  SquishStyles: PropTypes.arrayOf(SquishStyle),
  VinDecodeVinDataStyle,
  VinDecodeSquishVinDataStyle,
  VinStylesBasicInfo,
};
