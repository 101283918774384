import defaultsDeep from 'lodash/defaultsDeep';
import { compose, sample, sampleSize, toArray, filter, map, get, curry, pick, mapKeys } from 'lodash/fp';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { FeatureFlagModel } from 'client/data/models/feature-flag';
import { withMetrics } from 'client/data/api/api-metrics';
import { DEFAULT_SEO_CONTEXT, SITE_URL } from 'site-modules/shared/pages/seo';
import { VehicleModel } from './vehicle';
import { PageModel } from './page';

const PUB_STATES = ['NEW', 'NEW_USED'];
const filterPubStsFn = item => PUB_STATES.some(st => item.pubStates[st]);

const pickNameAndSlug = compose(
  mapKeys(key => (key === 'niceName' ? 'slug' : key)),
  pick(['name', 'niceName'])
);
const selectRandomModel = compose(
  pickNameAndSlug,
  sample,
  get('models')
);
const buildMMYObject = curry((year, make) => ({
  make: pickNameAndSlug(make),
  model: selectRandomModel(make),
  year,
}));

function getRandomizedMakes(count, context) {
  const takeNumberOfRandomMakes = compose(
    sampleSize(count),
    filter(filterPubStsFn),
    toArray
  );
  return context.resolveValue('makes', VehicleModel).then(takeNumberOfRandomMakes);
}

function getCount(match) {
  return parseInt(match.count, 10);
}

function getMakeModelsByYear(year, count, context) {
  const getMakeModels = compose(
    sampleSize(count),
    get('makes')
  );
  const url = `/vehicle/v2/makes?year=${year}`;
  return withMetrics(EdmundsAPI, context)
    .fetch(url)
    .then(response => response.json())
    .then(getMakeModels);
}

export const SEOModel = createModelSegment('seo', [
  {
    path: 'headContent',
    update(data) {
      return Promise.resolve(defaultsDeep(data, DEFAULT_SEO_CONTEXT));
    },
  },
  {
    path: 'randomizedMakes.Count.{count}',
    resolve(match, context) {
      return getRandomizedMakes(getCount(match), context);
    },
  },
  {
    path: 'randomizedMakeModel.{year}.Count.{count}',
    resolve(match, context) {
      return getMakeModelsByYear(match.year, getCount(match), context).then(map(buildMMYObject(match.year)));
    },
  },
  {
    path: 'pageProfile',
    async resolve(match, context) {
      const pathname = await context.resolveValue('location.pathname', PageModel);
      const url = `/seo/v1/pageprofile/?url=${SITE_URL}${pathname}`;

      return withMetrics(EdmundsAPI, context).fetchJson(url);
    },
  },
  {
    path: 'similarPages["{pathname}"]',
    async resolve(match, context) {
      const { pathname } = match;
      const pathnameLocation = pathname.replace('.html', '');
      const url = `/seo/v1/similarpage?url=${SITE_URL}${pathname}&pageNum=1&pageSize=50`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(get('results'))
        .then(results => results.filter(({ location }) => location !== pathnameLocation))
        .catch(() => []);
    },
  },
  {
    path: 'seoExperiments',
    async resolve(match, context) {
      const pathname = await context.resolveValue('location.pathname', PageModel);
      const url = `/seo/v1/experiments/?url=${SITE_URL}${pathname}`;
      const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          url,
          { timeout: 1000, cache: extendedApiCache ? 'force-cache' : undefined } // Added for CORE caching
        )
        .then(get('experiments'))
        .catch(() => []);
    },
  },
  {
    path: 'bestRankings',
    async resolve(match, context) {
      const pathname = await context.resolveValue('location.pathname', PageModel);
      const url = `/seo/v1/rankings/?url=${pathname}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .catch(() => {});
    },
  },
]);
