import React from 'react';

export function MarketoMunchkin() {
  const sandbox = [
    'allow-modals',
    'allow-forms',
    'allow-scripts',
    'allow-same-origin',
    'allow-popups',
    'allow-top-navigation-by-user-activation',
    'allow-downloads',
  ].join(' ');

  const markup = `
    <!DOCTYPE html>
    <html>
        <head></head>
        <body>
            <script type="text/javascript">
                ;(function () {
                    var didInit = false
                    function initMunchkin() {
                        if (didInit === false) {
                            didInit = true
                            Munchkin.init("461-NMJ-598");
                        }
                    }
                    var s = document.createElement("script")
                    s.type = "text/javascript"
                    s.async = true
                    s.src = "//munchkin.marketo.net/munchkin.js"
                    s.onreadystatechange = function () {
                        if (this.readyState == "complete" || this.readyState == "loaded") {
                            initMunchkin()
                        }
                    }
                    s.onload = initMunchkin
                    document.getElementsByTagName("head")[0].appendChild(s)
                })()
            </script>
        </body>
    </html>
    `;

  return <iframe title="Marketo Munchkin Script" sandbox={sandbox} srcDoc={markup} src="" frameBorder={0} />;
}
