import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { DATA_TYPE } from 'client/site-modules/shared/constants/home-vehicle-search-constants';
import { AutoCompleteOptionHighlightedLabel } from 'site-modules/shared/components/home-vehicle-search-autocomplete/home-vehicle-search-helper-components';

export function AutoCompleteOption({
  search,
  search: { dataType, autoSuggestValue, autoSuggestValueFull },
  highlighted,
  isActive,
  query,
  onSubmitHandler,
}) {
  const isMakeLink = dataType === DATA_TYPE.MAKE_LINK;
  const isMultipleSuggest = dataType === DATA_TYPE.MULTIPLE_SUGGEST;
  const isSearchHistory = dataType === DATA_TYPE.SEARCH_HISTORY;

  function clickHandler() {
    onSubmitHandler(search);
  }

  const buttonContent = isMakeLink ? (
    <div className="see-all-make-link value py-0_5">{autoSuggestValue}</div>
  ) : (
    <span className="fw-normal">
      {isMultipleSuggest && <Fragment>in </Fragment>}
      <span className="value">
        {isSearchHistory && <i className="pe-0_5 icon-history" aria-hidden />}
        {autoSuggestValue}
      </span>
    </span>
  );

  return (
    <Button
      color="link"
      className={classNames('auto-complete-option text-start px-1', isActive, {
        // This check is for the "multiple suggestions" per auto suggest which includes labels,
        // and the results should have more padding than the label
        'px-1_5': isMultipleSuggest,
        // Different styles for auto suggested values and "see all make" link.
        'py-0_5 my-0': !isMakeLink,
        'py-0 mt-0_5': isMakeLink,
        'see-all-make-link-button': isMakeLink,
      })}
      onClick={clickHandler}
      block
      data-auto-suggest-value-full={autoSuggestValueFull}
    >
      {highlighted ? (
        <AutoCompleteOptionHighlightedLabel key={autoSuggestValueFull} search={search} query={query} />
      ) : (
        buttonContent
      )}
    </Button>
  );
}

AutoCompleteOption.propTypes = {
  search: PropTypes.shape({
    autoSuggestValue: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  isActive: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  query: PropTypes.string,
};

AutoCompleteOption.defaultProps = {
  highlighted: false,
  query: '',
};
