import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Navbar from 'reactstrap/lib/Navbar';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { LogoSection } from './logo-section';
import { SearchBox } from './search-box';
import { NavigationLinks } from './navigation-links';
import { InsiderSection } from './insider-section';

export const XL_BREAKPOINT = 1200;

// Navigation for tablets, laptops and desktop

export function GlobalNavigationDesktop({ showSearchBar, pathname, isAppraisalLinkExcluded, shop3436Recipe }) {
  const [isOpenSearchDropDown, setOpenSearchDropDown] = useState(false);

  const isSHOP3436Enabled = shop3436Recipe.includes('chal');

  const onSearchDropDownOpen = useCallback(() => {
    if (window.innerWidth < XL_BREAKPOINT) {
      setOpenSearchDropDown(true);
    }
  }, []);

  const onSearchDropDownClose = useCallback(() => {
    if (window.innerWidth < XL_BREAKPOINT) {
      setOpenSearchDropDown(false);
    }
  }, []);

  return (
    <Navbar
      className="global-navigation-desktop d-flex flex-wrap flex-sm-nowrap flex-row align-items-center justify-content-between px-0_5"
      aria-label="Main Navigation"
    >
      <div
        className={classnames(
          'd-flex align-items-center w-sm-auto w-100 justify-content-center flex-column flex-sm-row flex-md-row',
          { 'flex-basis-100': showSearchBar && !isSHOP3436Enabled && isOpenSearchDropDown }
        )}
      >
        {!isOpenSearchDropDown && <LogoSection />}

        {showSearchBar && !isSHOP3436Enabled && (
          <SearchBox
            className={classnames('mw-100 w-100', { 'ps-sm-0_5 ps-md-1_5': !isOpenSearchDropDown })}
            closeStyle={{ paddingLeft: '1.5rem' }}
            onDropDownOpen={onSearchDropDownOpen}
            onDropDownClose={onSearchDropDownClose}
            onCrossClick={onSearchDropDownClose}
            pathname={pathname}
            isMobile={false}
          />
        )}
      </div>

      <div className="navigation-links-col">
        <NavigationLinks
          className="d-flex flex-row justify-content-around justify-content-md-center align-items-center"
          pathname={pathname}
          renderAppraisalLink={!isAppraisalLinkExcluded}
        />
      </div>

      {showSearchBar && isSHOP3436Enabled && (
        <div
          className={classnames('w-sm-auto w-100', { 'flex-basis-100 me-md-0_5': shop3436Recipe === 'chal1' })}
          style={shop3436Recipe === 'chal1' ? { maxWidth: '200px', marginRight: '0.5rem', flexBasis: '100%' } : null}
        >
          <SearchBox
            className={classnames('mw-100 w-100', { 'ps-sm-0_5 ps-md-1_5': !isOpenSearchDropDown })}
            onDropDownOpen={onSearchDropDownOpen}
            onDropDownClose={onSearchDropDownClose}
            onCrossClick={onSearchDropDownClose}
            pathname={pathname}
            isMobile={false}
          />
        </div>
      )}

      <FeatureFlag name="insider">
        <div className="d-flex align-items-center flex-no-shrink">
          <InsiderSection className="d-flex flex-row flex-nowrap justify-content-end ms-auto" isGlobalNavDesktop />
        </div>
      </FeatureFlag>
    </Navbar>
  );
}

GlobalNavigationDesktop.propTypes = {
  pathname: PropTypes.string,
  showSearchBar: PropTypes.bool,
  isAppraisalLinkExcluded: PropTypes.bool,
  shop3436Recipe: PropTypes.string,
};

GlobalNavigationDesktop.defaultProps = {
  pathname: '',
  showSearchBar: true,
  isAppraisalLinkExcluded: false,
  shop3436Recipe: 'ctrl',
};
