import React, { PureComponent, Children } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { get, debounce } from 'lodash';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { getElementOffsetWidth } from 'client/utils/dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { EngagementHandler } from 'client/engagement-handlers/index';
import { CarouselEngagementHandler } from 'client/engagement-handlers/carousel-engagement-handler/carousel-engagement-handler';

import { DIRECTIONS, calcNextSlide, fireSwipeEvent, counterClassName } from './utils';

import './carousel.scss';

EngagementHandler.register(CarouselEngagementHandler);

export const PrevArrow = props => (
  <div className="left carousel-control">
    <button
      aria-label={props.ariaLabel || 'previous slide'}
      className={classnames('icon-prev', props.className)}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  </div>
);
PrevArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
PrevArrow.defaultProps = {
  onClick: null,
  className: '',
  ariaLabel: '',
  disabled: false,
};

export const NextArrow = props => (
  <div className="right carousel-control">
    <button
      aria-label={props.ariaLabel || 'next slide'}
      className={classnames('icon-next', props.className)}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  </div>
);
NextArrow.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
NextArrow.defaultProps = {
  onClick: null,
  className: '',
  ariaLabel: '',
  disabled: false,
};

/**
 * IMPORTANT NOTE: THE CAROUSEL WILL NOT WORK IF YOUR DIRECT DESCENDANT OF THE <CAROUSEL/> IS NOT A <DIV/>
 *                 (THIS INCLUDES COMPONENTS).  NOTICE IN EXAMPLE 2 THAT YOU MUST WRAP EACH OF YOUR COMPONENTS
 *                 IN A <DIV/>.  IF YOU DO NOT LIKE THE EXTRA DIV, YOU CAN GET AROUND THIS BY USING A `render`
 *                 FUNCTION INSTEAD OF A CUSTOM COMPONENT.
 *
 *
 * based on https://github.com/akiran/react-slick
 * @returns {XML}
 * @constructor
 * @example
 * import {Carousel} from 'site-modules/shared/carousel'
 * <Carousel>
 *     <div>Slide1</div>
 *     <div>Slide2</div>
 *     <div>Slide3</div>
 * </Carousel>
 *
 * @example 2 - passing options
 * <Carousel autoplay={true}>
 *     <div><YourComponent vehicle={vehicleA}/></div>
 *     <div><YourComponent vehicle={vehicleB}/></div>
 *     <div><YourComponent vehicle={vehicleC}/></div>
 * </Carousel>
 * in addition to options specified in https://github.com/akiran/react-slick you may pass
 */
export class Carousel extends PureComponent {
  constructor(props) {
    super(props);
    this.setSliderRefs = element => {
      this.slider = element;
      this.element =
        element && element.innerSlider && element.innerSlider.list && element.innerSlider.list.parentElement;
    };
    this.slide = this.slide.bind(this);
  }

  componentDidMount() {
    if (this.props.arrows && this.element) {
      const prevArrow = this.element.querySelector('.left.carousel-control');
      const nextArrow = this.element.querySelector('.right.carousel-control');
      if (prevArrow && nextArrow) {
        prevArrow.addEventListener('click', this.onPrevClick, false);
        nextArrow.addEventListener('click', this.onNextClick, false);
      }
    }
    if (this.slider && this.props.currentSlideIndex) {
      this.slider.slickGoTo(this.props.currentSlideIndex);
    }
    if (this.props.scrollable) {
      this.element.addEventListener('wheel', this.wheelListener, { passive: false });
    }
  }

  componentWillUnmount() {
    if (this.props.arrows && this.element) {
      const prevArrow = this.element.querySelector('.left.carousel-control');
      const nextArrow = this.element.querySelector('.right.carousel-control');
      if (prevArrow && nextArrow) {
        prevArrow.removeEventListener('click', this.onPrevClick, false);
        nextArrow.removeEventListener('click', this.onNextClick, false);
      }
    }
    if (this.props.scrollable) {
      this.element.removeEventListener('wheel', this.wheelListener, { passive: false });
    }
  }

  onPrevClick = () => {
    this.onSwipe(this.props.vertical ? DIRECTIONS.VERTICAL.PREV : DIRECTIONS.HORIZONTAL.PREV);
  };

  onNextClick = () => {
    this.onSwipe(this.props.vertical ? DIRECTIONS.VERTICAL.NEXT : DIRECTIONS.HORIZONTAL.NEXT);
  };

  onBeforeChange = (from, to) => {
    if (this.props.beforeChange) {
      this.props.beforeChange(from, to);
    }
  };

  onAfterChange = to => {
    if (this.props.afterChange) {
      this.props.afterChange(to);
    }
  };

  /**
   * Handles carousel slide swipe event,
   * dispatches custom DOM event 'carousel.swipe'
   *
   * @param {Number} direction Swipe direction
   * @return {void}
   */
  onSwipe = direction => {
    const vertical = this.props.vertical;
    const infinite = this.props.infinite;
    const total = get(this.slider, 'innerSlider.state.slideCount', 0);
    const currentSlide = get(this.slider, 'innerSlider.state.currentSlide', 0);
    const nextSlide = calcNextSlide(currentSlide, total, direction, vertical, infinite);

    if (this.props.autoplay && Children.count(this.props.children) > 1) {
      this.pause();
      this.play();
    }

    if (this.props.trackSwipeEvent) {
      fireSwipeEvent({
        props: this.props,
        slider: this.slider,
        element: this.element,
        direction,
        nextSlide,
      });
    }

    if (this.props.swipeEvent) {
      this.props.swipeEvent(nextSlide);
    }
  };

  slide = x => {
    // Specifically doesn't trigger on x === 0 for when user scrolls up/down.
    if (x > 0) {
      this.onNextClick();
      this.next();
    } else if (x < 0) {
      this.onPrevClick();
      this.prev();
    }
  };

  wheelListener = debounce(
    e => {
      e.preventDefault();
      const { target } = e;
      const { scrollWidth, scrollLeft } = target;
      const max = scrollWidth - getElementOffsetWidth(e.target);

      if (scrollLeft + e.deltaX < 0 || scrollLeft + e.deltaX > max) {
        e.preventDefault();
        e.target.scrollLeft = Math.max(0, Math.min(max, scrollLeft + e.deltaX));
      }

      this.slide(e.deltaX);
    },
    500,
    { leading: true, trailing: false, maxWait: 1000 }
  );

  /**
   * Сhanges current slide on next slide
   *
   * @return {void}
   */
  next = () => {
    this.slider.slickNext();
  };

  /**
   * Сhanges current slide on previous slide
   *
   * @return {void}
   */
  prev = () => {
    this.slider.slickPrev();
  };

  /**
   * Сhanges current slide to given slide number
   *
   * @return {void}
   */
  goto = slide => {
    this.slider.slickGoTo(slide);
  };

  play = () => {
    if (this.slider) {
      this.slider.slickPlay();
    }
  };

  pause = () => {
    if (this.slider) {
      this.slider.slickPause();
    }
  };

  renderSlider() {
    const pageCounterClassName = counterClassName(this.props.pageCounter);
    const hasMoreThanOneSlide = Children.count(this.props.children) > 1;
    // default for slidesToShow is 1 from https://react-slick.neostack.com/docs/api/#slidesToShow
    const isShowOneSlide = this.props.slidesToShow === 1 || !this.props.slidesToShow;
    const className = classnames(
      'slick-carousel',
      { 'show-one': isShowOneSlide },
      { fluid: this.props.fluid },
      pageCounterClassName,
      this.props.className
    );

    const options = {
      ...this.props,
      dots: this.props.dots || !!pageCounterClassName,
      beforeChange: this.onBeforeChange,
      afterChange: this.onAfterChange,
      onSwipe: this.onSwipe,
      arrows: this.props.arrows && hasMoreThanOneSlide,
      autoplay: this.props.autoplay && hasMoreThanOneSlide,
      draggable: this.props.draggable && hasMoreThanOneSlide,
      swipe: this.props.swipe && hasMoreThanOneSlide,
      className,
    };

    return (
      <Slider ref={this.setSliderRefs} {...options}>
        {this.props.children}
      </Slider>
    );
  }

  /**
   * Renders Carousel layout
   *
   * @return {ReactElement} Carousel layout
   */
  render() {
    const { fluid, ariaLive, autoplay } = this.props;
    /* we don't want to overwhelm screen reader users with unimportant aria-live updates. so, we
       don't want to use aria-live in combination with autoplay */
    const ariaLiveAdjusted = autoplay || ariaLive === 'off' ? null : ariaLive;
    return (
      <Row className="g-0">
        <Col xs={{ size: fluid ? 12 : 10, offset: fluid ? 0 : 1 }} aria-live={ariaLiveAdjusted}>
          {this.renderSlider()}
        </Col>
      </Row>
    );
  }
}

/**
 * Carousel props definition
 * @type {Object}
 */
Carousel.propTypes = {
  className: PropTypes.string,
  fluid: PropTypes.bool,
  vertical: PropTypes.bool /* Make sure each slide has height set with css or it may not work. */,
  infinite: PropTypes.bool,
  contentType: PropTypes.string,
  contentDetails: PropTypes.string,
  customEventData: PropTypes.shape({}),
  beforeChange: PropTypes.func,
  afterChange: PropTypes.func,
  swipeEvent: PropTypes.func,
  children: PropTypes.node,
  arrows: PropTypes.bool,
  pageCounter: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])]),
  dots: PropTypes.bool,
  dotsClass: PropTypes.string /* supported options: "slick-dots" "slick-dots gray" */,
  autoplay: PropTypes.bool,
  draggable: PropTypes.bool,
  swipe: PropTypes.bool,
  adaptiveHeight: PropTypes.bool,
  currentSlideIndex: PropTypes.number,
  noAdsRefresh: PropTypes.bool,
  skipSwipeTracking: PropTypes.bool,
  ariaLive: PropTypes.string, // you should pass in "off" if you want to disable aria-live="polite"
  trackSwipeEvent: PropTypes.bool,
  scrollable: PropTypes.bool,
};

/**
 * Carousel props definition
 * @type {Object}
 */
Carousel.defaultProps = {
  className: '',
  fluid: true,
  vertical: false,
  infinite: true,
  contentType: 'content',
  contentDetails: '',
  customEventData: null,
  beforeChange: null,
  afterChange: null,
  swipeEvent: null,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  children: null,
  arrows: true,
  pageCounter: false,
  dots: false,
  dotsClass: 'slick-dots',
  autoplay: false,
  draggable: true,
  swipe: true,
  adaptiveHeight: false,
  currentSlideIndex: 0,
  noAdsRefresh: false,
  skipSwipeTracking: false,
  ariaLive: 'polite', // you should pass in "off" if you want to disable aria-live="polite"
  trackSwipeEvent: true,
  scrollable: false,
};
