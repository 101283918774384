import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Container from 'reactstrap/lib/Container';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import { getIsAppraisalLinkExcluded } from 'site-modules/shared/utils/appraisal/check-links-visible';

import { GlobalNavigationMobileWrapper } from './global-navigation-mobile-wrapper';
import { GlobalNavigationMobile } from './global-navigation-mobile';
import { GlobalNavigationDesktop } from './global-navigation-desktop';

import './global-navigation.scss';

function GlobalNavigationUI({ isMobile, showSearchBar, pathname, pageName, selectedFacets, shop3436Recipe }) {
  const isAppraisalLinkExcluded = getIsAppraisalLinkExcluded(pageName, selectedFacets);

  return (
    <div className="global-navigation w-100" data-tracking-parent="edm-entry-global-nav">
      <Container className="px-0">
        {isMobile ? (
          <GlobalNavigationMobileWrapper>
            <GlobalNavigationMobile
              showSearchBar={showSearchBar}
              pathname={pathname}
              isAppraisalLinkExcluded={isAppraisalLinkExcluded}
            />
          </GlobalNavigationMobileWrapper>
        ) : (
          <GlobalNavigationDesktop
            showSearchBar={showSearchBar}
            pathname={pathname}
            isAppraisalLinkExcluded={isAppraisalLinkExcluded}
            shop3436Recipe={shop3436Recipe}
          />
        )}
      </Container>
    </div>
  );
}

GlobalNavigationUI.propTypes = {
  pathname: PropTypes.string,
  isMobile: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  shop3436Recipe: PropTypes.string,
  pageName: PropTypes.string,
  selectedFacets: PropTypes.shape({}),
};

GlobalNavigationUI.defaultProps = {
  pathname: '',
  isMobile: false,
  showSearchBar: true,
  shop3436Recipe: 'ctrl',
  pageName: null,
  selectedFacets: {},
};

const mapStateToProps = state => ({
  isMobile: state.mobile,
  pathname: get(state, 'pageContext.location.pathname'),
  pageName: get(state, 'pageContext.page.name'),
  selectedFacets: get(state, 'inventory.searchResultsFilter'),
  shop3436Recipe: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'shop-3436-global-nav-ai-search',
    defaultVal: 'ctrl',
  }),
});

export const GlobalNavigation = connect(mapStateToProps)(GlobalNavigationUI);
