import React, { Component, Fragment } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Nav from 'reactstrap/lib/Nav';
import { AnimatedCollapse } from 'site-modules/shared/utils/collapse-utils';
import classNames from 'classnames';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { CmsEntities, bindToContent } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { INDUSTRY_CENTER_GLOBAL_NAV_PATH } from 'site-modules/shared/constants/industry-center';
import { EdmundsLogo } from 'site-modules/shared/components/edmunds-logo/edmunds-logo';
import { renderNavEntries, renderNavBarEntries } from './industry-center-global-navigation-renderer';

import './industry-center-global-navigation.scss';

export class IndustryCenterGlobalNavigationUI extends Component {
  static propTypes = {
    globalNavData: CmsEntities.Content,
    breakpoint: PropTypes.string,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    globalNavData: DEFAULT_CONTENT,
    breakpoint: '',
    isMobile: false,
  };

  state = {
    openedEntryId: '',
    isMenuOpen: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.closeSubMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeSubMenu);
  }

  static getDerivedStateFromProps(nextProps) {
    const { breakpoint, isMobile } = nextProps;
    return { isSmall: isMobile || breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' };
  }

  onBackButtonClick = e => {
    e.preventDefault();
    this.setState(() => ({
      openedEntryId: '',
    }));
  };

  closeSubMenu = e => {
    const target = e.target;
    if (!this.state.isSmall) {
      this.setState(prevState => ({
        openedEntryId: prevState.openedEntryId === target.id ? prevState.openedEntryId : '',
      }));
    }
  };

  toggleSubMenu = e => {
    e.preventDefault();
    const id = e.target.id;
    this.setState(prevState => ({ openedEntryId: id === prevState.openedEntryId ? '' : id }));
  };

  toggleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  render() {
    const { globalNavData } = this.props;
    const { openedEntryId, isMenuOpen, isSmall } = this.state;
    const renderEntries = isSmall ? renderNavBarEntries : renderNavEntries;
    const entries = renderEntries(globalNavData, this.toggleSubMenu);
    const openedEntry = entries.find(entry => entry.id === openedEntryId) || {};
    const dealerUrl = 'https://dealerportal.edmunds.com/';
    const contactEntry =
      !!globalNavData && globalNavData.childEntries && globalNavData.childEntries.find(entry => entry.id === 'contact');
    const contactData = Object.assign(
      { title: 'Contact Us', url: '/industry/contact-us.html' },
      (contactEntry && contactEntry.contentMetadata) || {}
    );

    return (
      <Container
        className="industry-center-global-nav bg-faded px-0"
        data-tracking-parent="industry-center-global-nav"
        fluid
      >
        <Container>
          <Row>
            <Col className="px-1">
              <Nav className="w-100 d-flex flex-column flex-lg-row navbar-toggleable-md">
                {isSmall ? (
                  <div className="py-1 w-100">
                    <Button
                      color="link"
                      className="px-0 py-0_5 text-brand-primary-darker text-decoration-none"
                      onClick={this.toggleMenu}
                      aria-label="Menu"
                    >
                      <EdmundsLogo className="pt-0 nav-brand" path={null} />
                      <i
                        className={classNames('ps-0_5', {
                          'icon-arrow-down3': !isMenuOpen,
                          'icon-arrow-up3': isMenuOpen,
                        })}
                      />
                    </Button>
                    <Button
                      color="link"
                      href={dealerUrl}
                      className="text-primary-darker mb-0 px-0 pt-0_5 pb-0_25 float-end h4"
                      data-tracking-id="nav_sign_in"
                    >
                      <span className="icon-user3" aria-hidden />
                    </Button>
                  </div>
                ) : (
                  <Fragment>
                    <EdmundsLogo className="pt-0 nav-brand" path="/industry/" trackingId={null} />
                    <div
                      className={classNames('links d-flex flex-column justify-content-center flex-lg-row w-100', {
                        'collapse-open': !!openedEntryId,
                      })}
                    >
                      {entries.map(entry => entry.el)}
                    </div>
                    <Button
                      color="link"
                      href={contactData.url}
                      className="text-brand-primary-darker bg-faded heading-5 px-0 py-1 my-0 fw-normal"
                    >
                      {contactData.title}
                    </Button>
                    <Button
                      color="link"
                      href={dealerUrl}
                      className="text-brand-primary-darker bg-faded heading-5 px-1 py-0 my-0 text-decoration-none fw-normal"
                      data-tracking-id="nav_sign_in"
                    >
                      <span className="icon-user3 px-0_5" aria-hidden />
                      <span>Sign In</span>
                    </Button>
                  </Fragment>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
        {isSmall ? (
          <AnimatedCollapse
            className="collapse-navbar justify-content-center w-100"
            isOpen={isMenuOpen}
            overflowOnExpanded
          >
            <ul className="list-unstyled m-0 px-1 py-1">
              {entries.map(entry => entry.el)}
              {!!openedEntryId && (
                <div className="collapse-navbar-children px-1 py-0_5">
                  <Button
                    color="link"
                    className="collapse-back text-white legal p-0 border-0"
                    onClick={this.onBackButtonClick}
                  >
                    Back
                  </Button>
                  <ul className="list-unstyled m-0">{openedEntry.children}</ul>
                </div>
              )}
              <hr />
              <li>
                <Button color="link" href={contactData.url} className="heading-5 px-0 py-1 text-white fw-normal">
                  {contactData.title}
                </Button>
              </li>
              <li>
                <Button
                  color="link"
                  href={dealerUrl}
                  className="heading-5 px-0 py-1 text-white text-decoration-none fw-normal"
                  data-tracking-id="nav_sign_in"
                >
                  Sign In
                </Button>
              </li>
            </ul>
          </AnimatedCollapse>
        ) : (
          <AnimatedCollapse className="collapse-subnav d-flex justify-content-center w-100" isOpen={!!openedEntryId}>
            <ul className="list-unstyled m-0">{openedEntry.children}</ul>
          </AnimatedCollapse>
        )}
      </Container>
    );
  }
}

export const stateToPropsConfig = {
  globalNavData: bindToContent(INDUSTRY_CENTER_GLOBAL_NAV_PATH),
};

export const mapStateToProps = state => ({
  breakpoint: get(state, 'pageContext.breakpointDeprecated'),
  isMobile: state.mobile,
});

export const IndustryCenterGlobalNavigation = connect(mapStateToProps)(
  connectToModel(IndustryCenterGlobalNavigationUI, stateToPropsConfig)
);
