import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { profileScreenMethods } from 'site-modules/shared/components/profile/profile-screen-methods';
import { FirebaseErrors } from 'site-modules/shared/components/profile/firebase-errors';

function ProfileFormErrorsUI({ fieldsOrder, validationErrors, firebaseError, toggleForgotPassword, className }) {
  if (isEmpty(validationErrors) && isEmpty(firebaseError)) {
    return null;
  }

  return (
    <ul className={classnames('text-danger ps-1 mb-0', className)} id="profile-form-errors" aria-label="Error message">
      {fieldsOrder.map(field => (validationErrors[field] ? <li key={field}>{validationErrors[field]}</li> : null))}
      {firebaseError && <FirebaseErrors error={firebaseError} toggleForgotPassword={toggleForgotPassword} />}
    </ul>
  );
}

ProfileFormErrorsUI.propTypes = {
  toggleForgotPassword: PropTypes.func.isRequired,
  validationErrors: PropTypes.objectOf(PropTypes.string),
  fieldsOrder: PropTypes.arrayOf(PropTypes.string),
  firebaseError: PropTypes.shape({}),
  className: PropTypes.string,
};

ProfileFormErrorsUI.defaultProps = {
  validationErrors: {},
  fieldsOrder: [],
  firebaseError: null,
  className: null,
};

export const ProfileFormErrors = profileScreenMethods(ProfileFormErrorsUI);
