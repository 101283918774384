import React from 'react';

export function MarketoForm() {
  const sandbox = [
    'allow-modals',
    'allow-forms',
    'allow-scripts',
    'allow-same-origin',
    'allow-popups',
    'allow-top-navigation-by-user-activation',
    'allow-downloads',
  ].join(' ');

  const markup = `
    <!DOCTYPE html>
    <html>
        <head></head>
        <body>
            <form id="mktoForm_1234"></form>
            <script src="//go.edmunds.com/js/forms2/js/forms2.min.js"></script>
            <script>
                MktoForms2.loadForm("//go.edmunds.com", "461-NMJ-598", 1234)
            </script>
        </body>
    </html>
    `;

  return <iframe title="Marketo Form" sandbox={sandbox} srcDoc={markup} src="" frameBorder={0} />;
}
