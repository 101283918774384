import { compact, get } from 'lodash';
import { getModelState } from 'client/data/luckdragon/model';
import { buildMakeModelDefaultYear, VehicleModel } from 'client/data/models/vehicle';
import { getMediaImageUrl } from 'client/utils/image-helpers';
import { getBreadcrumbSchema } from 'client/seo/schemas/breadcrumbs';
import { getFAQSchema } from 'client/seo/schemas/faq';
import { AppraisalModel, buildAllStylesAppraisalPath } from 'client/data/models/appraisal';
import { generateCanonical } from 'site-modules/shared/pages/seo';
import { OG_TYPE_PRODUCT } from 'site-modules/shared/constants/og-types';
import { buildCoreBreadcrumbs, getUrlPathFromParams } from 'site-modules/shared/utils/core-breadcrumbs-builder';
import { getAppraisalValues } from 'site-modules/shared/utils/appraisal/common-utils';

/**
 * Gets seo image object if image url exist.
 * @param {string} imageUrl
 * @returns {object|null}
 */
function getSeoImage(imageUrl) {
  return imageUrl
    ? {
        url: getMediaImageUrl(imageUrl),
        width: 300,
        height: 200,
      }
    : null;
}

// Get default style id:
// - when only one styleId presented in queryStyleIds, then use it;
// - when mostPopularStyle.id is presented then use it;
// - when mostPopularStyle.id isn't presented use usedDefaultStyle.id;
// - when mostPopularStyle.id and usedDefaultStyle.id aren't presented use newDefaultStyle.id.

export function getSelectedStyleId(vehicle, queryStyleIds, allStylesForVehicle = []) {
  const styleIds = queryStyleIds ? queryStyleIds.split(',') : [];
  if (styleIds.length === 1 && allStylesForVehicle.includes(styleIds[0])) return styleIds[0];
  const { newDefaultStyleId, usedDefaultStyleId, mostPopularStyleId } = vehicle || {};
  return mostPopularStyleId || usedDefaultStyleId || newDefaultStyleId;
}

/**
 * Transforms array of style objects to array of string Style IDs
 * @param {object[]} styles
 * @returns {string[]}
 */
export const transformStylesToStyleIds = styles => styles.map(({ id }) => id.toString());

function buildVehicleName(estimatedVehicle) {
  const make = get(estimatedVehicle, 'makeName');
  const model = get(estimatedVehicle, 'modelName');
  const year = get(estimatedVehicle, 'year');

  return `${year} ${make} ${model}`;
}

export function getFaqContent({ appraisalStyles, estimatedStyleId, estimatedVehicle, pathname }) {
  const { minAppraisalValue, maxAppraisalValue, cleanTradeInValue, cleanPrivatePartyValue } = getAppraisalValues({
    appraisalStyles,
    estimatedStyleId,
  });
  const vehicleName = buildVehicleName(estimatedVehicle);
  const make = get(estimatedVehicle, 'makeName');
  const model = get(estimatedVehicle, 'modelName');
  const learnMoreLink = `https://www.edmunds.com${pathname}`;
  const learnMore = `<a href="${learnMoreLink}">Learn more</a>`;

  return compact([
    Boolean(cleanTradeInValue && cleanPrivatePartyValue) && {
      question: `What is the Blue Book Value of a ${vehicleName}?`,
      answer: `The term "Blue Book Value" might refer to the Kelley Blue Book value, but is often used as a generic expression for a given vehicle's market value. You can find the market value of your ${vehicleName} on Edmunds. You'll need to know some basic facts about your vehicle, such as the mileage, condition, option packages and trim level. As a general guide, the trade-in value of a ${vehicleName} with no options and driven roughly 12,000 miles per year would be worth about ${cleanTradeInValue} for one in "Clean" condition and about ${cleanPrivatePartyValue} if you were selling it as a private party. If the vehicle is in worse shape, you'll want to deduct a couple thousand dollars for each of the lower two condition levels. ${learnMore}`,
    },
    Boolean(cleanTradeInValue && cleanPrivatePartyValue) && {
      question: `How much is a ${make} ${model} worth?`,
      answer: `A number of factors will affect how much a ${make} ${model} is worth. For starters, you'll need information such as the year, mileage, condition level, options and trim level. As a general guide, the trade-in value of a ${vehicleName} with no options and driven roughly 12,000 miles per year would be worth about ${cleanTradeInValue} for one in "Clean" condition and about ${cleanPrivatePartyValue} if you're selling it as a private party. If you need a more accurate number, head to Edmunds, input your vehicle's details and you'll get an accurate appraisal. ${learnMore}`,
    },
    Boolean(cleanTradeInValue && cleanPrivatePartyValue) && {
      question: `What is the value of a ${vehicleName}?`,
      answer: `The value of a ${vehicleName}, or any vehicle, is determined by its age, mileage, condition, trim level and installed options. As a rough estimate, the trade-in value of a ${vehicleName} with no options and driven roughly 12,000 miles per year would be worth about ${cleanTradeInValue} for one in "Clean" condition and about ${cleanPrivatePartyValue} if you're selling it as a private party. Head to Edmunds for a more detailed appraisal, where you can see its estimated dealer retail value along with the values for other condition levels. ${learnMore}`,
    },
    Boolean(cleanTradeInValue && cleanPrivatePartyValue) && {
      question: `Should I buy a ${vehicleName}?`,
      answer: `There is no one perfect vehicle for everyone, so it is difficult to make a broad assessment of the ${vehicleName}. We recommend you read Edmunds expert reviews and consumer reviews to make that buying decision for yourself. When in doubt, ask to test-drive the ${vehicleName} and see how it feels. ${learnMore}`,
    },
    {
      question: `How much is a ${vehicleName} worth?`,
      answer:
        minAppraisalValue && maxAppraisalValue
          ? `The value of a used ${vehicleName} ranges from ${minAppraisalValue} to ${maxAppraisalValue}, based on vehicle condition, mileage, and options. <a href="${learnMoreLink}">Get a free appraisal here.</a>`
          : `We don't have enough data on the ${vehicleName} to provide an accurate appraisal at this time. Either the vehicle is too recent, or we don't have enough transaction data to accurately estimate its value. If you'd like to try another vehicle, take a look at our <a href="https://www.edmunds.com/appraisal/">free appraisal page.</a>`,
    },
    {
      question: `Is ${vehicleName} a good car?`,
      answer: `To understand if the ${vehicleName} is a good vehicle for you, check out Edmunds' expert and consumer reviews and ratings. To see if it's priced right, <a href="${learnMoreLink}">check out Edmunds' free appraisal calculator.</a>`,
    },
  ]);
}

/**
 * Gets appraiser seo object.
 * @param {object} state
 * @param {object} props
 * @returns {object}
 */
export function getAppraiserSeoObject(state, props) {
  const {
    location,
    match: { params },
  } = props;
  const { pathname } = location;

  const modelState = getModelState(state);
  const defaultYear = modelState.get(buildMakeModelDefaultYear(params), VehicleModel);
  const vehicleStyleId = modelState.get('estimatedAppraisalStyle', VehicleModel);
  const estimatedVehicle = modelState.get(`styles.${vehicleStyleId}`, VehicleModel);
  const appraisalStyles = modelState.get(buildAllStylesAppraisalPath(params), AppraisalModel);

  const { minAppraisalValue, maxAppraisalValue } = getAppraisalValues({
    appraisalStyles,
    estimatedStyleId: vehicleStyleId,
  });
  const hasEstimatedPrices = minAppraisalValue && maxAppraisalValue;

  const imageUrl = estimatedVehicle && estimatedVehicle.photo;
  const vehicleTitle = buildVehicleName(estimatedVehicle);

  return {
    title: `${vehicleTitle} Value - ${
      hasEstimatedPrices ? `${minAppraisalValue}-${maxAppraisalValue}` : "What's it Worth?"
    } | Edmunds`,
    description:
      appraisalStyles && appraisalStyles.length > 1
        ? `How much is a ${vehicleTitle}? Edmunds provides free, instant appraisal values. Check the ${get(
            appraisalStyles,
            [0, 'styleName'],
            vehicleTitle
          )} price, the ${get(
            appraisalStyles,
            [1, 'styleName'],
            vehicleTitle
          )} price, or any other ${vehicleTitle} price with Edmunds car appraisal value calculator.`
        : `Get the value of your used ${vehicleTitle} based on options, mileage and condition - find your trade-in value, private party and dealer retail.`,
    keywords: `${vehicleTitle}, trade-in value, private party, Edmunds.com`,
    canonical: generateCanonical(location),
    image: getSeoImage(imageUrl),
    type: OG_TYPE_PRODUCT,
    jsonld: [
      getBreadcrumbSchema(
        buildCoreBreadcrumbs({
          makeModelSubmodelYear: estimatedVehicle && {
            make: { name: estimatedVehicle.makeName, slug: estimatedVehicle.makeSlug },
            model: { name: estimatedVehicle.modelName, slug: estimatedVehicle.modelSlug },
            submodels: estimatedVehicle.submodels,
            year: estimatedVehicle.year,
          },
          params,
          defaultYear,
          pageDisplayName: 'Appraisal value',
          includeLastLink: true,
          urlPath: getUrlPathFromParams(params, 'appraisal-value'),
        })
      ),
      getFAQSchema({
        entries: getFaqContent({
          appraisalStyles,
          estimatedStyleId: vehicleStyleId,
          estimatedVehicle,
          pathname,
        }),
      }),
    ],
  };
}
