import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'reactstrap/lib/Button';
import { SocialShareDialog } from 'client/site-modules/shared/components/social-share-dialog/social-share-dialog';

import './share-this-chart.scss';

export function ShareThisChart({
  pageTitle,
  pageUrl,
  shareText,
  shareableWidgetData,
  socialIcons,
  trackingId,
  socialTrackingId,
  className,
}) {
  const [isModalOpened, setIsModalOpened] = useState(false);

  function toggleModal() {
    setIsModalOpened(!isModalOpened);
  }

  if (!pageTitle || !pageUrl) return null;

  return (
    <Fragment>
      <Button
        color="link"
        onClick={toggleModal}
        className={classnames('share-this-chart d-flex align-items-center size-16 text-transform-none p-0', className)}
        data-testid="share-chart-btn"
        data-tracking-id={trackingId}
      >
        <span className="icon-share3 me-0_25" aria-hidden />
        {shareText}
      </Button>
      <SocialShareDialog
        isOpen={isModalOpened}
        toggle={toggleModal}
        pageTitle={pageTitle}
        pageUrl={pageUrl}
        socialIcons={socialIcons}
        socialTrackingId={socialTrackingId}
        shareableWidgetData={shareableWidgetData}
      />
    </Fragment>
  );
}

ShareThisChart.propTypes = {
  pageTitle: PropTypes.string,
  pageUrl: PropTypes.string,
  shareText: PropTypes.string,
  shareableWidgetData: PropTypes.shape({
    widgetName: PropTypes.string,
    imageSrc: PropTypes.string,
    defaultWidth: PropTypes.number,
    defaultHeight: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    defaultTitle: PropTypes.string,
  }),
  socialIcons: PropTypes.arrayOf(PropTypes.string),
  trackingId: PropTypes.string,
  socialTrackingId: PropTypes.string,
  className: PropTypes.string,
};

ShareThisChart.defaultProps = {
  pageTitle: '',
  pageUrl: '',
  shareText: 'Share this chart',
  shareableWidgetData: {},
  socialIcons: undefined,
  trackingId: null,
  socialTrackingId: undefined,
  className: 'text-primary-darker',
};
